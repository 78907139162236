import React, { useState, useEffect } from "react";

// third-party imports
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@mui/material";

// project imports
import DownExpandIcon from "../../svg/DownExpandIcon";
import Button from "../../ui/Button";
import RightExtandIcon from "../../svg/RightExtandIcon";
import LeftExtandIcon from "../../svg/LeftExtandIcon";
import MeetTypeToggle from "./MeetTypeToggle";
import useAppointmentStore from "../../../store/appointmentsStore";
import { IN_PERSON, ONLINE } from "../../../utils/constants";
import { DayProps } from "../../../types/appointments";

const Day: React.FC<DayProps> = ({ day, date, isActive, onClick }) => {
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelow400 = useMediaQuery("(max-width:25rem)");

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onClick();
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: "20px",
        paddingTop: "0.75rem",
        paddingBottom: "0.375rem",
        paddingInline: isMobile
          ? isBelow400
            ? "0.25rem"
            : "0.375rem"
          : "0.5rem",
        marginRight: isMobile ? "0.25rem" : "0.625rem",
        cursor: "pointer",
        backgroundColor: isActive
          ? "var(--neuro-button-bg-success)"
          : "transparent",
        "&:hover": {
          backgroundColor: isActive
            ? "var(--neuro-button-bg-success)"
            : "var(--neuro-bg-light-grey-secondary)",
        },
      }}
    >
      <Typography
        sx={{
          color: isActive
            ? "var(--neuro-white-text)"
            : "var(--neuro-black-text)",
          fontSize: "var(--neuro-font-size-x-small)",
          lineHeight: "120%",
          fontFamily: "var(--neuro-font-family-roboto-slab)",
        }}
      >
        {day}
      </Typography>
      <Typography
        sx={{
          fontWeight: "var(--neuro-font-weight-bold)",
          color: isActive
            ? "var(--neuro-white-text)"
            : "var(--neuro-black-text)",
          fontSize: "var(--neuro-font-size-tiny)",
          lineHeight: "var(--neuro-line-height-micro)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
        }}
      >
        {date}
      </Typography>
    </Box>
  );
};

const AppointmentCalendar = () => {
  // props & state values
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [monthChangeCount, setMonthCountChange] = useState<number>(0);
  const [visibleDays, setVisibleDays] = useState<Date[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelow400 = useMediaQuery("(max-width:25rem)");

  const {
    selectedMeetingType,
    setSelectedMeetingType,
    selectedDate,
    setSelectedDate,
  } = useAppointmentStore();

  // callbacks & functions
  const handleMeetTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSelectedMeetingType(event.target.checked === true ? IN_PERSON : ONLINE);
  };

  useEffect(() => {
    updateVisibleDays(currentMonth);
  }, [monthChangeCount]);

  const updateVisibleDays = (date: Date) => {
    const days: Date[] = [];
    const startDate = new Date(date);

    // Adjust startDate to the current day if it is in the past
    if (startDate < new Date()) {
      startDate.setDate(new Date().getDate());
    }

    for (let i = 0; i < 7; i++) {
      days.push(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        )
      );
    }

    setVisibleDays(days);
    setCurrentMonth(days[3]); // Update current month for display purposes
  };

  const handlePrevWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newDate = new Date(visibleDays[0]);
    newDate?.setDate(newDate?.getDate() - 7);
    updateVisibleDays(newDate);
  };

  const handleNextWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newDate = new Date(visibleDays[6]);
    newDate?.setDate(newDate?.getDate() + 1);
    updateVisibleDays(newDate);
  };

  const handleMonthClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMonthClose = () => {
    setAnchorEl(null);
  };

  const handleMonthSelect = (month: number, year: number) => {
    setCurrentMonth(new Date(year, month, 1));
    setMonthCountChange(monthChangeCount + 1);
    handleMonthClose();
  };

  const getMonthOptions = () => {
    const now = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = (now.getMonth() + i) % 12;
      const year = now.getFullYear() + Math.floor((now.getMonth() + i) / 12);
      months.push({
        index: monthIndex,
        year: year,
        label: new Date(year, monthIndex, 1).toLocaleString("default", {
          month: "long",
        }),
      });
    }

    return months;
  };

  const handleBackToToday = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const today = new Date();
    setCurrentMonth(today);
    setSelectedDate(today);
    setMonthCountChange(monthChangeCount + 1);
  };

  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
          flexWrap: isMobile ? "wrap" : "initial",
        }}
      >
        <Button
          variant="outlined"
          id="basic-button"
          className="outlined-secondary-button"
          onClick={handleMonthClick}
          aria-controls={open ? "basic-menu" : undefined}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "10rem",
            height: "1.75rem",
          }}
        >
          {currentMonth?.toLocaleString("default", {
            month: "short",
            year: "numeric",
          })}
          <DownExpandIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: "9.375rem",
              borderRadius: "0.625rem",
            },
          }}
          onClose={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            handleMonthClose();
          }}
        >
          {getMonthOptions().map(({ index, year, label }) => (
            <MenuItem
              key={`${index}-${year}`}
              onClick={(event: React.MouseEvent<HTMLLIElement>) => {
                event.stopPropagation();
                handleMonthSelect(index, year);
              }}
            >
              {label} {year}
            </MenuItem>
          ))}
        </Menu>

        <Button
          variant="outlined"
          className="outlined-secondary-button"
          onClick={handleBackToToday}
          sx={{
            height: "1.75rem",
          }}
        >
          Back to Today
        </Button>

        <Box
          sx={{
            display: isMobile ? "flex" : "block",
            width: isMobile ? "100%" : "auto",
            justifyContent: isMobile ? "center" : "normal",
            marginTop: isMobile ? "0.313rem" : 0,
          }}
        >
          <MeetTypeToggle
            checked={selectedMeetingType === ONLINE ? false : true}
            onChange={handleMeetTypeChange}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid var(--neuro-secondary_border)",
          backgroundColor: "var(--neuro-white-text)",
          borderRadius: "0.75rem",
          // width: "100%",
          minHeight: "4.25rem",
          maxHeight: "4.25rem",
          paddingInline: isMobile
            ? isBelow400
              ? "0.438rem"
              : "0.75rem"
            : "1.25rem",
        }}
      >
        <IconButton
          // size="small"
          disableRipple
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            marginRight: isMobile
              ? isBelow400
                ? "0.375rem"
                : "0.5rem"
              : "0.625rem",
            "&: hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handlePrevWeek}
        >
          <LeftExtandIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            height: "4.25rem",
            // overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {visibleDays.map((day, index) => (
            <React.Fragment key={index}>
              {index > 0 && day.getDate() === 1 && (
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: "var(--neuro-secondary_border)",
                    height: "3.75rem",
                    color: "var(--neuro-secondary_border)",
                    marginRight: isMobile ? "0.25rem" : "0.625rem",
                    marginBlock: "0.25rem",
                  }}
                />
              )}

              <Day
                day={day
                  ?.toLocaleString("default", { weekday: "short" })
                  ?.toUpperCase()}
                date={day.getDate()?.toString()}
                isActive={selectedDate?.toDateString() === day?.toDateString()}
                onClick={() => setSelectedDate(day)}
              />
            </React.Fragment>
          ))}
        </Box>
        <IconButton
          disableRipple
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            "&: hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleNextWeek}
        >
          <RightExtandIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AppointmentCalendar;
