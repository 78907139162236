import { useEffect } from "react";
import { Box } from "@mui/material";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import AppointmentConfirmation from "../appointments/AppointmentConfirmation";
import NewFlow from "../appointments/AppointmentBooking/NewFlow";
import AllAppointments from "../appointments/Appointments/AllAppointments";
import {
  isClinicAdminOrAdmin,
  isDoctorOrAdminOrClinicAdmin,
} from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import useAppStore from "../../store/appStore";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";

const NewPatientAppointments = () => {
  // props & state values
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  const { selectedPatientInList } = useAppStore();

  const path = window.location.pathname;

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
    },
    { label: "Appointments" },
  ];

  // callbacks & functions
  const handleBookAppointment = () => {
    if (isClinicAdminOrAdmin(userRole)) {
      navigate(`/patients/${selectedPatientInList?.id}/appointments/book`);
    } else {
      navigate(
        `/appointments/patient/${selectedPatientInList?.id}/appointments/book`
      );
    }
  };

  useEffect(() => {
    if (!selectedPatientInList) {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [selectedPatientInList]);

  return (
    <>
      {path.includes("details") ? (
        <AppointmentConfirmation patientId={selectedPatientInList?.id} />
      ) : path.includes("book") ? (
        <NewFlow
          patientId={selectedPatientInList?.id}
          patientName={
            `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}` ||
            ""
          }
          patientEmail={selectedPatientInList?.user?.email || ""}
          patientContact={selectedPatientInList?.user?.mobile || ""}
          from="patient-list"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {selectedPatientInList ? (
            <Box
              sx={{
                display: "flex",
                gap: "1.25rem",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                paddingTop: "1rem",
                paddingInline: "0.875rem",
              }}
            >
              <CustomBreadcrumbs items={breadcrumbItems} />
              {isClinicAdminOrAdmin(userRole) && (
                <Button
                  color="primary"
                  variant="contained"
                  className="primary-button"
                  onClick={handleBookAppointment}
                  sx={{ height: "2.25rem" }}
                >
                  Book Appointment
                </Button>
              )}
            </Box>
          ) : (
            <ComponentHeader title="">
              {isClinicAdminOrAdmin(userRole) && (
                <Button
                  color="primary"
                  variant="contained"
                  className="primary-button"
                  onClick={handleBookAppointment}
                >
                  Book Appointment
                </Button>
              )}
            </ComponentHeader>
          )}

          <AllAppointments
            from="patient-list"
            patientId={selectedPatientInList?.id}
          />
        </Box>
      )}
    </>
  );
};

export default NewPatientAppointments;
