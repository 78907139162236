import React, { useState, useEffect } from "react";
import { Box, CircularProgress,  useMediaQuery } from "@mui/material";
import Modal from "../ui/Modal";
import OtpModal from "./OtpModal";
import OtpTypeSelect from "./OtpTypeSelect";
import AbhaAccountSelect from "./AbhaAccountSelect";
import { sendOtp,verifyOtp, sendOtpViaAbhaCommunication ,verifyViaAbhaCommunication ,verifyAbhaAccount} from "../../services/authService"; // Example API functions
import useAppStore from "../../store/appStore";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
interface VerificationModalProps {
  input:string;
  step:any;
  setStep:any;
  open: boolean;
  onClose: () => void;
}

const VerificationModal: React.FC<VerificationModalProps> = ({ open, onClose , input , step ,setStep}) => {
  console.log("step",step);
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  // const [otpTypes, setOtpTypes] = useState<string[]>([]);
  // const [abhaAccounts, setAbhaAccounts] = useState<string[]>([]);
  // const [otpType, setOtpType] = useState("");
  // const [selectedAbhaAccount, setSelectedAbhaAccount] = useState<string>();
  const [stepData, setStepData] = useState<{ [key: string]: any }>({}); // Data for each step
  const {showSnackbar } = useAppStore();
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);


  // Fetch ABHA accounts before showing AbhaAccountSelect
  useEffect(() => {
    const fetchData = async () => {
      if (step === "mobileOtpModal") {
        setLoading(true);
        try {
          const result = await sendOtpViaAbhaCommunication(input);
          const resultData = await result.data;
          showSnackbar(result.message, "success");
          setStepData(prev=>({...prev,sendOtpViaAbhaCommunication:resultData}))
          console.log(result);
        } catch (error:any) {
          showSnackbar(error.response.data.message, "error");
          console.error("Error fetching ABHA accounts:", error);
          onClose();
        } finally {
          setLoading(false);
        }
      }else if(step==="emailOtpModal"){
        setLoading(true);
        try {
          const result = await sendOtp({emailOrMobileOrAadhaarOrAbhaNumber:input});
          showSnackbar(result.message, "success");
          setStepData(prev=>({...prev,sendEmailOtp:{...result,email:input}}))
          console.log(result);
        } catch (error:any) {
          showSnackbar(error.response.data.message, "error");
          console.error("Error fetching ABHA accounts:", error);
          onClose();
        } finally {
          setLoading(false);
        }

      }
    };
  
    fetchData();
  }, [step, input]);

  const verifyAbhaCommunicationOtp = async (data:any)=>{
       setLoading(true);
        try {
          const result = await verifyViaAbhaCommunication(stepData["sendOtpViaAbhaCommunication"].txnId,data.otp);
          const resultData = await result.data;
          showSnackbar(result.message, "success");
          setStepData(prev=>({...prev,verifyViaAbhaCommunication:resultData}))
          setStep("abhaAccountSelect")
        } catch (error:any) {
          showSnackbar(error.response.data.message, "error");
          console.error("Error fetching ABHA accounts:", error);
          onClose();
        } finally {
          setLoading(false);
        }
  }

  const resendAbhaCommunicationOtp = async ()=>{
    setReloading(true);
    try {
      const result = await sendOtpViaAbhaCommunication(input);
    const resultData = await result.data;
    showSnackbar(result.message, "success");
    setStepData({...resultData})
    } catch (error:any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setReloading(false);
    }
  }

  const verifyEmailOtp = async (data:any)=>{
    setLoading(true);
    try {
      console.log("sendEmailOTP",stepData["sendEmailOtp"])
      const result : any = await verifyOtp(stepData["sendEmailOtp"].email, data.otp,stepData["sendEmailOtp"].otpToken);
      showSnackbar(result?.message, "success");
      login(result?.accessToken, result?.refreshToken);
      navigate("/dashboard");
    } catch (error:any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  const resendEmailOtp = async ()=>{
    setReloading(true);
    try {
      const result = await sendOtpViaAbhaCommunication(input);
      const resultData = await result.data;
      showSnackbar(result.message, "success");
      setStepData({...resultData});
    } catch (error:any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setReloading(false);
    }
  }


  const handleOtpTypeSelect = (type: string) => {
    // setOtpType(type);
    console.log(type);
    setStep("abhaAccountSelect");
  };

  const handleAbhaAccountSelect =async (account: any) => {
    setReloading(true);
    try {
      // setSelectedAbhaAccount(account);
      const result = await verifyAbhaAccount(stepData["verifyViaAbhaCommunication"].txnId,account.ABHANumber,stepData["verifyViaAbhaCommunication"].token);
      const responseData = await result?.data
      showSnackbar(result?.message, "success");
      login(responseData?.accessToken, responseData?.refreshToken);
      navigate("/dashboard");
    } catch (error:any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setReloading(false);
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10rem" }}>
          <CircularProgress />
        </Box>
      );
    }

    switch (step) {
      case "otpTypeSelect":
        return <OtpTypeSelect otpTypes={[]} onSelect={handleOtpTypeSelect} />;
      case "abhaAccountSelect":
        return <AbhaAccountSelect  accounts={stepData["verifyViaAbhaCommunication"]?.accounts||[]} onAccountSelect={handleAbhaAccountSelect} />;
      case "mobileOtpModal":
        return  <OtpModal type="mobile" verifyOtp={verifyAbhaCommunicationOtp} resendOtp={resendAbhaCommunicationOtp} loader={loading} reloader={reloading} />;
      case "aadhaarOtpModal":
        return  <OtpModal type="aadhaar" verifyOtp={verifyAbhaCommunicationOtp} resendOtp={resendAbhaCommunicationOtp} loader={loading} reloader={reloading} />;
      case "emailOtpModal":
        return  <OtpModal type="email" verifyOtp={verifyEmailOtp} resendOtp={resendEmailOtp} loader={loading} reloader={reloading} />;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      width={isMobile ? "20rem" : "31.25rem"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        {renderContent()}
      </Box>
    </Modal>
  );
};

export default VerificationModal;
