import api from "./apiService"; // Assuming `api` is your Axios instance

export const fetchDoctors = (
  type: string,
  start?: string | null,
  end?: string | null,
  search?: string
) => {
  let params;

  if (start && end) {
    params = {
      start,
      end,
      type,
    };
  } else {
    params = {
      type,
    };
  }

  params = {
    ...params,
    search: search || "",
  };

  return api.get(`/api/doctors`, { params });
};

export const fetchDoctorById = (id: string) => {
  return api.get(`/api/doctors/${id}`);
};

export const addDoctor = (doctorData: any) => {
  return api.post(`/api/doctors`, doctorData);
};

export const updateDoctor = (id: string, doctorData: any) => {
  return api.put(`/api/doctors/${id}`, doctorData);
};

export const deleteDoctor = (id: string) => {
  return api.delete(`/api/doctors/${id}`);
};

// doctor consultation settings
export const addDoctorAvailability = (doctorData: any) => {
  return api.post(`/api/WeeklyAvailability/create`, doctorData);
};

export const updateDoctorAvailability = (doctorData: any) => {
  return api.put(`/api/WeeklyAvailability/reset`, doctorData);
};

// doctor work experience
export const fetchDoctorWorkExperiences = (id: string) => {
  return api.get(`/api/doctor/workExperience/${id}/all`);
};

export const deleteDoctorWorkExperience = (id: string) => {
  return api.delete(`/api/doctor/workExperience/${id}`);
};

export const updateDoctorWorkExperience = (id: string, data: any) => {
  return api.put(`/api/doctor/workExperience/${id}`, data);
};

export const addDoctorWorkExperience = (data: any) => {
  return api.post(`/api/doctor/workExperience`, data);
};

// doctor leave settings
export const fetchDoctorLeaveSetting = (id: string) => {
  return api.get(`/api/doctor/leaveSetting/${id}/all`);
};

export const deleteDoctorLeaveSetting = (id: string) => {
  return api.delete(`/api/doctor/leaveSetting/${id}`);
};

export const updateDoctorLeaveSetting = (id: string, data: any) => {
  return api.put(`/api/doctor/leaveSetting/${id}`, data);
};

export const addDoctorLeaveSetting = (data: any) => {
  return api.post(`/api/doctor/leaveSetting`, data);
};

// doctor academic qualifications
export const fetchDoctorAcademicQualification = (id: string) => {
  return api.get(`/api/doctor/academicQualification/${id}/all`);
};

export const deleteDoctoracademicQualification = (id: string) => {
  return api.delete(`/api/doctor/academicQualification/${id}`);
};

export const updateDoctoracademicQualification = (id: string, data: any) => {
  return api.put(`/api/doctor/academicQualification/${id}`, data);
};

export const addDoctoracademicQualification = (data: any) => {
  return api.post(`/api/doctor/academicQualification`, data);
};

// doctor payment settings
export const updateDoctorPayemntSettings = (data: any) => {
  return api.put(`/api/doctor/paymentSettings`, data);
};

// doctor profession profile
export const updateDoctorProfessionalProfile = (data: any) => {
  return api.put(`/api/doctor/professionalProfile`, data);
};

// doctor personal profile
export const updateDoctorPersonalProfile = (data: any) => {
  return api.put(`/api/doctor/personalProfile`, data);
};

export const addDoctorPersonalProfile = (data: any) => {
  return api.post(`/api/doctor/personalProfile`, data);
};