import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import Input from "../../ui/Input";
// import useAuthStore from "../../../store/authStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import useProfileStore from "../../../store/profileStore";
import { DateTime } from "luxon";
import useAppStore from "../../../store/appStore";
import {
  PatientProfileFormInputs,
  PersonalInfoFormProps,
} from "../../../types/profile";
import { patientProfileSchema } from "../../../validations/profileValidation";
import { countryCode } from "../../../utils/common";

const PersonalInfoPage: React.FC<PersonalInfoFormProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    updateProfile,
    loadingUpdateProfile,
    errorUpdateProfile,
    updatedProfileDetails,
    cleanUpUpdateProfileState,
  } = useProfileStore();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm<PatientProfileFormInputs>({
    resolver: yupResolver(patientProfileSchema),
    defaultValues: {},
  });

  const [profileImagePreview, setProfileImagePreview] = useState<
    string | undefined
  >();
  const { showSnackbar } = useAppStore();
  const initialValues = useRef<any>(null);

  // const { user } = useAuthStore();

  // callbaks & functions
  useEffect(() => {
    if (patientId) {
      getProfileDetails(patientId, "PATIENT");
    } else if (!patientId) {
      getProfileDetails();
    }
  }, [patientId]);

  useEffect(() => {
    if (loadingUpdateProfile) {
      showSnackbar("Updating profile, please wait", "info");
    } else if (errorUpdateProfile) {
      showSnackbar("Could not update profile, please try again", "error");
      cleanUpUpdateProfileState();
    } else if (updatedProfileDetails) {
      showSnackbar("Profile updated", "success");
      if (patientId) {
        getProfileDetails(patientId, "PATIENT");
      } else if (!patientId) {
        getProfileDetails();
      }
      cleanUpUpdateProfileState();
    }
  }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (profileDetails) {
      const dateOfBirth = profileDetails.dateOfBirth
        ? DateTime.fromISO(profileDetails.dateOfBirth)
        : null; // Handle null or invalid dates safely
      // const dateOfBirth = new Date(profileDetails.dateOfBirth);
      // const dateWithZeroTime = setTimeToZero(dateOfBirth);
      const defaultValues = {
        firstName: profileDetails.user.firstName || "",
        lastName: profileDetails.user.lastName || "",
        // mobile: profileDetails.user.mobile || "",
        mobile: profileDetails.user.mobile
          ? profileDetails.user.mobile.replace(/^\+91/, "")
          : "",
        email: profileDetails.user.email || "",
        height: profileDetails.height || "",
        weight: profileDetails.weight || "",
        city: profileDetails.city || "",
        state: profileDetails.state || "",
        zipCode: profileDetails.zipCode || "",
        country: profileDetails.country || "",
        gender: profileDetails.gender || "",
        age: profileDetails.age || "",
        dateOfBirth: dateOfBirth,
        streetAddress: profileDetails.streetAddress || "",
        profileImage: profileDetails.user?.profileImageUrl || undefined,
      };
      reset(defaultValues);
      setProfileImagePreview(profileDetails.user.profileImageUrl);
      initialValues.current = defaultValues;
    }
  }, [profileDetails, reset]);

  const onSubmit: SubmitHandler<PatientProfileFormInputs> = async (data) => {
    console.log("data", data);
    // setLoading(true);
    // setLoading(false);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] as any;
    if (file && profileDetails) {
      setProfileImagePreview(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("profileUrl", file);
      formData.append("table", "USER");
      formData.append("profileImageUrl", profileDetails.user.profileImageUrl);
      // await updateProfile(profileDetails.user.id, formData);
      updateProfile(profileDetails.user.id, formData);
      // showSnackbar(`Your profile updated successfully.`, "success");
    }
  };

  const handleRemoveImage = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setProfileImagePreview(undefined);
    setValue("profileImage", null);
    event.stopPropagation();
  };

  const onBlurHandler = async (event: any) => {
    let key = event?.target.name as keyof PatientProfileFormInputs;
    let value = event?.target.value as any;
    const initialValue = initialValues.current
      ? initialValues.current[key]
      : undefined;

    if (key === ("mobile" as keyof PatientProfileFormInputs)) {
      value = value?.includes("+") ? value : `+91${value}`;
    }

    const isValid = await trigger(key);
    if (!isValid) return;

    if (initialValue === value) return;

    const table = profileDetails
      ? key in profileDetails
        ? "PATIENT"
        : "USER"
      : null;

    const data = { [key]: value, table };

    if (value && profileDetails) {
      updateProfile(profileDetails.user.id, data);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.688rem",
        height: from === "patient-list" ? "calc(100% - 3.25rem)" : "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          margin: "0 auto",
          background: "var(--neuro-white-text)",
          borderRadius: "0.625rem",
          padding: "1.25rem",
        }}
      >
        <Grid container rowGap={1} columnGap={2.75}>
          <Grid item container xs={12} spacing={1}>
            <Grid
              item
              container
              direction="column"
              spacing={1}
              sx={{
                height: "max-content",
              }}
              xs={12}
              md={6}
            >
              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Full name *"
                  required
                  {...register("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "baseline",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      maxWidth: "6.25rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-smaller-plus)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                        color: "var(--neuro-black-text)",
                        lineHeight: "120%",
                      }}
                    >
                      Code
                    </Typography>
                    <Select
                      sx={{
                        maxWidth: "6.25rem",
                        height: "2.625rem",
                        backgroundColor:
                          "var(--neuro-input-bg-primary) !important",
                        borderRadius: "0.625rem !important",
                        "& .MuiInputBase-root": {
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          border: "none !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                      }}
                      name="countryCode"
                      value={"+91"}
                      // onChange={handleChange}
                      fullWidth
                    >
                      {countryCode?.map((code) => (
                        <MenuItem key={code.id} value={code.value}>
                          {code.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Input
                    // startAdornment={<Typography>+91</Typography>}
                    type="text"
                    className="input-primary"
                    label="Phone *"
                    fullWidth
                    {...register("mobile")}
                    error={!!errors?.mobile}
                    helperText={errors?.mobile?.message}
                    onBlur={(e: any) => onBlurHandler(e)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "9.375rem", xl: "11.875rem" },
                  height: { xs: "9.375rem", xl: "11.875rem" },
                  border: "0.125rem solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                  marginLeft: { xs: 0, md: "6.25rem" },
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: { xs: "9.375rem", xl: "11.875rem" },
                    height: { xs: "9.375rem", xl: "11.875rem" },
                    border: "0.125rem solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                disabled
                type="email"
                className="input-primary"
                label="E-mail *"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  marginBottom: "0.375rem",
                }}
              >
                Gender *
              </Typography>

              <Controller
                defaultValue="MALE"
                name="gender"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    row
                    {...field}
                    onBlur={(e: any) => onBlurHandler(e)}
                  >
                    <FormControlLabel
                      value="MALE"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="FEMALE"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gender && (
                <Typography color="error" variant="body2">
                  {errors.gender.message}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item container spacing={2.5} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  marginBottom: "0.375rem",
                }}
              >
                Date of Birth Or Age*
              </Typography>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      value={field.value || null} // Ensure value is not undefined
                      onChange={(date) => {
                        field.onChange(date);
                        onBlurHandler({
                          target: {
                            name: "dateOfBirth",
                            value: date,
                          },
                        });
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              height: "2.5rem",
                              borderRadius: "0.625rem",
                              backgroundColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              borderColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              "& >fieldset": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:hover": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:active": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:focus": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "1px solid var(--neuro-bg-light-grey-secondary)",
                                },
                              "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "1px solid var(--neuro-bg-light-grey-secondary)",
                                },
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                      // renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />

              {errors.dateOfBirth && (
                <Typography color="error" variant="body2">
                  {/* {errors.dateOfBirth.message} */}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                // className="input-secondary"
                className="input-primary"
                endAdornment={<Typography>Years</Typography>}
                sx={{
                  "& >.MuiInputBase-root": {
                    height: "2.5rem",
                  },
                }}
                type="text"
                {...register("age")}
                error={!!errors.age}
                helperText={errors.age?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Height"
                {...register("height")}
                error={!!errors.height}
                helperText={errors.height?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                className="input-primary"
                type="text"
                label="Weight"
                {...register("weight")}
                error={!!errors.weight}
                helperText={errors.weight?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Input
              type="text"
              // className="input-primary"
              label="Street address"
              {...register("streetAddress")}
              error={!!errors.streetAddress}
              helperText={errors.streetAddress?.message}
              className="input-secondary-textarea"
              rows={3}
              multiline
              sx={{
                "& textarea": {
                  minHeight: "30px",
                },
              }}
              onBlur={(e: any) => onBlurHandler(e)}
            />
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="City"
                {...register("city")}
                error={!!errors.city}
                helperText={errors.city?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="State"
                {...register("state")}
                error={!!errors.state}
                helperText={errors.state?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Zip code"
                {...register("zipCode")}
                error={!!errors?.zipCode}
                helperText={errors?.zipCode?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Country"
                {...register("country")}
                error={!!errors.country}
                helperText={errors.country?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Box mt={7} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Button type="submit" className="dark-button" variant="contained">
              Edit
            </Button>
            <Button
              type="submit"
              className="success-button"
              variant="contained"
              color="success"
            >
              Save
            </Button>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default PersonalInfoPage;
