import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import NationalHealthAuthorityLogo from "../../shared/NationalHealthAuthorityLogo";
import Button from "../../ui/Button";
import { useState } from "react";
// import AddAbhaAccountModal from "./AddAbhaAccountModal";
import useAuthStore from "../../../store/authStore";
import { isPatient } from "../../../utils/auth";
import AbhaSignUp from "../../auth/AbhaSignUp";
const NoAbhaDetails = () => {
  // Props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const [aadharModalOpen, setAadharModalOpen] = useState(false);
  // const [addAbhaAccountModalOpen, setAddAbhaAccountModalOpen] = useState(false);
  const { userRole, user } = useAuthStore();

  // callbacks & functions
  const onCloseAadharModal = () => {
    setAadharModalOpen(false);
  };
  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "max-content",
        height: "max-content",
        display: "flex",
        flexDirection: "column",
        paddingBlock: "0.625rem",
        paddingInline: "1.25rem",
        border: "0.063rem solid var(--neuro-secondary_border)",
        backgroundColor: "var(--neuro-white-text)",
        borderRadius: "0.625rem",
      }}
    >
      <Box
        sx={{
          marginBottom: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography
          sx={{
            fontWeight: "var(--neuro-font-weight-bold)",
            fontSize: "var(--neuro-font-size-smallest)",
            lineHeight: "var(--neuro-line-height-extra-small)",
            letterSpacing: "0.031rem",
            color: "var(--neuro-darkgrey_border)",
            width: isMobile ? "60%" : "42%",
            textAlign: "center",
          }}
        >
          Ayushman Bharat Digital Mission
        </Typography>

        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
            color: "var(--neuro-darkgrey_border)",
          }}
        >
          Approved By
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
            color: "var(--neuro-darkgrey_border)",
            width: isMobile ? "50%" : "40%",
            textAlign: "center",
          }}
        >
          Creating India's Digital Health Ecosystem
        </Typography>

        <NationalHealthAuthorityLogo width="5.938rem" height="3.75rem" />
      </Box>
      <Box
        sx={{
          marginBottom: "1.25rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          className="success-gradient-button"
          sx={{ width: "max-content" }}
          disabled={!isPatient(userRole)}
          onClick={() => setAadharModalOpen(true)}
        >
          Create Account Using Aadhaar
        </Button>

        <Link
          sx={{
            width: "max-content",
            cursor: !isPatient(userRole) ? "not-allowed" : "pointer",
          }}
          className="universal-link"
          onClick={(event) => {
            if (!isPatient(userRole)) {
              event.stopPropagation();
            } 
            // else {
            //   setAddAbhaAccountModalOpen(true);
            // }
          }}
        >
          Add Account Using Aadhaar
        </Link>
      </Box>

      {/* create abha account modal */}
      <AbhaSignUp
        open={aadharModalOpen}
        onClose={onCloseAadharModal}
        userId={user?.userId}
      />
      {/* add abha account modal */}
      {/* <AddAbhaAccountModal
        open={addAbhaAccountModalOpen}
        onClose={() => setAddAbhaAccountModalOpen(false)}
      /> */}
    </Box>
  );
};

export default NoAbhaDetails;
