import { Box } from "@mui/material";
import DoctorAvailabilityForm from "./DoctorAvailabilityForm";
import { useNavigate, useParams } from "react-router-dom";
import useAppStore from "../../store/appStore";
import { useEffect } from "react";

const DoctorAvailabilityFormPage = () => {
  // props & state values
  const { id } = useParams();
  const {
    showDoctorSubmenus,
    selectedDoctorInList,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
  } = useAppStore();
  const navigate = useNavigate();

  // callbacks & functions
  useEffect(() => {
    if (!showDoctorSubmenus || !id || id === "") {
      navigate(`/doctors`);
    }
  }, [showDoctorSubmenus, id]);

  const hanleClose = () => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
    navigate(`/doctors`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      <DoctorAvailabilityForm
        id={id}
        doctor={selectedDoctorInList}
        onClose={hanleClose}
      />
    </Box>
  );
};

export default DoctorAvailabilityFormPage;
