import React from "react";
import { Button, Typography, Box, Card, CardContent, CardMedia } from "@mui/material";

interface Account {
  ABHANumber: string;
  preferredAbhaAddress: string;
  name: string;
  gender: string;
  dob: string;
  verifiedStatus: string;
  verificationType: string;
  status: string;
  profilePhoto: string;
  kycVerified: boolean;
}

const AbhaAccountSelect: React.FC<{ accounts: Account[]; onAccountSelect: (account: Account) => void }> = ({
  accounts,
  onAccountSelect,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography variant="h6">Select ABHA Account</Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        {accounts.map((account) => (
          <Box
            key={account.ABHANumber}
            sx={{
              flexBasis: "calc(100% - 1rem)",
              maxWidth: "calc(100% - 1rem)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card  sx={{display: "flex", alignItems:"center"}}>
              <Box sx={{ height: 100,  width: 100}}>
              <CardMedia
                component="img"
                sx={{
                  height: 100, // Adjust the height as required
                  width: 100,  // Explicitly set the width
                  objectFit: "cover", // Ensure the image scales properly
                }}
                image={`data:image/png;base64,${account.profilePhoto}`}
                alt={account.name}
              />
              </Box>
              
              <CardContent sx={{paddingLeft:'1rem'}}>
                <Typography variant="h6">{account.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  ABHA Number: {account.ABHANumber}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Preferred Address: {account.preferredAbhaAddress}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {account.status}
                </Typography>
                <Button variant="contained" onClick={() => onAccountSelect(account)}>
                  Select
                </Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AbhaAccountSelect;
