import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import { completeRegistration } from "../../services/authService";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for the datepicker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import axios from "axios";
import useAppStore from "../../store/appStore";
import { SignUpPayloadDataType } from "../../types/auth";
import { PatientSignUpFormInputs } from "../../types/patients";
import { patientSignUpSchema } from "../../validations/patientValidation";
import { countryCode } from "../../utils/common";
import useAuthStore from "../../store/authStore";

const PatientSignUp = ({
  loadPatients,
  handleClose,
}: {
  loadPatients: any;
  handleClose: any;
}) => {
  // props & state values
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PatientSignUpFormInputs>({
    resolver: yupResolver(patientSignUpSchema),
  });

  const { showSnackbar } = useAppStore();
  const { clinicId, user } = useAuthStore();

  console.log("clinicId", clinicId);

  const [loading, setLoading] = useState(false);

  const [datePickerOpen, setDatePickerOpen] = useState(false); // New state for controlling DatePicker

  const [isSameMobileError, setIsSameMobileError] = useState(false);
  const [isSameEmailError, setIsSameEmailError] = useState(false);

  const age = watch("age");
  const dateOfBirth = watch("dateOfBirth");

  // callbacks & functions
  useEffect(() => {
    if (dateOfBirth) {
      const birthDate = DateTime.fromJSDate(dateOfBirth);
      const calculatedAge = DateTime.now().diff(birthDate, "years").years;
      setValue("age", Math.floor(calculatedAge).toString());
    }
  }, [dateOfBirth, setValue]);

  useEffect(() => {
    if (age && !isNaN(Number(age))) {
      setDatePickerOpen(false); // Close DatePicker if age is entered
      const year = DateTime.now().year - Number(age);
      const newDateOfBirth = DateTime.fromObject({
        year,
        month: 1,
        day: 1,
      }).toJSDate();
      setValue("dateOfBirth", newDateOfBirth);
    }
  }, [age, setValue]);

  const handleAgeFocus = () => {
    setDatePickerOpen(false); // Close DatePicker if age is entered
  };

  const onSubmit: SubmitHandler<PatientSignUpFormInputs> = async (data) => {
    setIsSameMobileError(false);
    setLoading(true);
    try {
      let payloadData: SignUpPayloadDataType;

      if (data.dateOfBirth && data.age) {
        payloadData = {
          adminUserId: user?.userId || "",
          email: data.email,
          fullName: data.fullName,
          age: data.age,
          gender: data.gender,
          dateOfBirth: data.dateOfBirth.toISOString().split("T")[0], // Convert to string or null
          mobile: `+91${data?.mobile}`,
          reasonsForAppointments: data.reasonsForAppointments || "",
          clinicId: clinicId || "",
        };

        console.log("payloadData in patient add", payloadData);
      } else {
        // If neither dateOfBirth nor age is provided, handle it appropriately.
        throw new Error("Either Date of Birth or Age must be provided.");
      }

      await completeRegistration(payloadData);
      loadPatients();
      handleClose();
      showSnackbar("Patient Created", "success");

      // Handle post-registration logic (e.g., redirect to dashboard or show success message)
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        if (error?.response?.data.message === "Mobile already exists") {
          setIsSameMobileError(true);
        } else if (
          error?.response?.data.message === "Please try another email address."
        ) {
          setIsSameEmailError(true);
        } else {
          showSnackbar(
            error?.response?.data.message ||
              "Could not create patient, please try again",
            "error"
          );
        }
      } else {
        showSnackbar(
          error?.response?.data.message ||
            "Could not create patient, please try again",
          "error"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: "2rem",
        mb: "2rem",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          //   height: "23.75rem",
          overflowY: "hidden",
          paddingRight: "10px",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <Input
              type="text"
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Full name*"
              {...register("fullName")}
              error={!!errors.fullName}
              helperText={errors.fullName?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                mb: "0.5rem",
                fontSize: "var(--neuro-font-size-smaller-plus)",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
                color: "var(--neuro-black-text)",
                lineHeight: "120%",
              }}
            >
              Gender*
            </Typography>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="MALE"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />

            {errors.gender && (
              <Typography color="error" variant="body2">
                {errors.gender.message}
              </Typography>
            )}
          </Grid>

          <Grid item container spacing={2.5} alignItems="flex-end">
            <Grid item xs={12} sm={5.5}>
              <Typography
                sx={{
                  marginBottom: "0.375rem",
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  color: "var(--neuro-black-text)",
                  lineHeight: "120%",
                }}
              >
                Date of Birth Or Age*
              </Typography>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      // value={field.value || undefined}
                      // onChange={(date) => field.onChange(date)}
                      open={datePickerOpen} // Control the open state
                      onOpen={() => setDatePickerOpen(true)}
                      onClose={() => setDatePickerOpen(false)}
                      value={
                        field.value
                          ? DateTime.fromJSDate(field.value)
                          : undefined
                      }
                      // disabled={isDobDisabled}
                      onChange={(date: DateTime | null) =>
                        field.onChange(date?.toJSDate())
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              height: "2.5rem",
                              borderRadius: "0.625rem",
                              backgroundColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              borderColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              "& >fieldset": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:hover": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:active": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:focus": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "0.063rem solid var(--neuro-bg-light-grey-secondary)",
                                },
                              "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "0.063rem solid var(--neuro-bg-light-grey-secondary)",
                                },
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                      // renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />

              {errors.dateOfBirth && (
                <Typography color="error" variant="body2">
                  {errors.dateOfBirth.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  color: "var(--neuro-black-text)",
                  lineHeight: "120%",
                  marginBottom: "0.375rem",
                }}
              >
                Or
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5.5}>
              <Input
                // className="input-secondary"
                className="input-primary"
                // disabled={isAgeDisabled}
                onFocus={handleAgeFocus}
                endAdornment={<Typography>Years</Typography>}
                sx={{
                  "& >.MuiInputBase-root": {
                    height: "2.5rem",
                  },
                }}
                type="text"
                {...register("age")}
                error={!!errors.age}
                helperText={errors.age?.message}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Input
              type="text"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Email*"
              {...register("email")}
              error={!!errors.email || isSameEmailError}
              helperText={
                isSameEmailError
                  ? "Email already exists"
                  : errors.email?.message
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Changed to column to stack elements vertically
                gap: "0.375rem",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.375rem",
                    maxWidth: "6.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-smaller-plus)",
                      fontFamily: "var(--neuro-font-family-roboto-condensed)",
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                    }}
                  >
                    Code
                  </Typography>
                  <Select
                    sx={{
                      maxWidth: "6.25rem",
                      height: "2.625rem",
                      backgroundColor:
                        "var(--neuro-input-bg-primary) !important",
                      borderRadius: "0.625rem !important",
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          "var(--neuro-input-bg-primary) !important",
                        borderRadius: "0.625rem !important",
                        border: "none !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                    }}
                    name="countryCode"
                    value={"+91"}
                    // onChange={handleChange}
                    fullWidth
                  >
                    {countryCode.map((code) => (
                      <MenuItem key={code.id} value={code.value}>
                        {code.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Input
                  type="text"
                  fullWidth
                  // className="input-secondary"
                  // startAdornment={<Typography>+91</Typography>}
                  className="input-primary"
                  sx={{
                    "& >.MuiInputBase-root": {
                      height: "2.5rem",
                    },
                  }}
                  label="Phone*"
                  {...register("mobile")}
                  error={!!errors.mobile || isSameMobileError}
                  // helperText={
                  // 	isSameMobileError
                  // 		? "Mobile already exists"
                  // 		: errors.mobile?.message
                  // }
                />
              </Box>
              {/* Error Message Below Select and Input */}
              {(isSameMobileError || errors.mobile) && (
                <Typography
                  sx={{
                    color: "error.main",
                    fontSize: "0.75rem",
                    marginTop: "0.25rem",
                  }}
                >
                  {isSameMobileError
                    ? "Mobile already exists"
                    : errors.mobile?.message}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Input
              className="input-textarea"
              sx={{
                "& >.MuiInputBase-root": {
                  backgroundColor: "var(--neuro-input-bg-primary) !important",
                },
                "& textarea": {
                  minHeight: "30px",
                },
              }}
              multiline
              rows={3}
              type="text"
              label="Reason for Appointment"
              {...register("reasonsForAppointments")}
              error={!!errors.reasonsForAppointments}
              helperText={errors.reasonsForAppointments?.message}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "2.75rem",
            mb: "3.5rem",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            className="success-button"
            color="success"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientSignUp;
