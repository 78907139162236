import React from "react";
import Button from "../../ui/Button";
import { RescheduleButtonProps } from "../../../types/appointments";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IconButton } from "@mui/material";
import Spinner from "../../ui/Spinner";

const ReScheduleAppointmentButton: React.FC<RescheduleButtonProps> = ({
  onClick,
  id,
  doctor,
  loading = false,
  isMobile = false,
}) => {
  return (
    <>
      {isMobile ? (
        <IconButton
          disableRipple
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            "& :hover": {
              padding: 0,
              backgroundColor: "transparent",
            },
          }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (doctor) {
              onClick(event, id, doctor);
            } else {
              onClick(event, id);
            }
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <CalendarTodayIcon
              sx={{ color: "var(--neuro-bg-primary-seagreen)" }}
            />
          )}
        </IconButton>
      ) : (
        <Button
          startIcon={
            <CalendarTodayIcon
              sx={{ color: "var(--neuro-bg-primary-seagreen)" }}
            />
          }
          color="secondary"
          variant="outlined"
          className="outlined-secondary-button"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (doctor) {
              onClick(event, id, doctor);
            } else {
              onClick(event, id);
            }
          }}
          sx={{
            height: "2.25rem",
          }}
        >
          {loading ? <Spinner /> : "Reschedule"}
        </Button>
      )}
    </>
  );
};

export default ReScheduleAppointmentButton;
