import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import Input from "../ui/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
// import axios from "axios";

// Define validation schema for Yup
const schema = yup.object().shape({
  abhaAddress: yup
    .string()
    .matches(
      /^(?=.{8,18}$)(?!.*\.\.)(?!.*__)[a-zA-Z0-9._]+$/,
      "ABHA address must be 8-18 characters, alphanumeric, and allow at most one dot (.) or underscore (_)."
    )
    .required("ABHA address is required"),
});

interface FormInputs {
  abhaAddress: string;
}

interface AbhaAddressModalProps {
  suggestions: string[]; // Array of suggestion strings
  verifySelectedAbhaAddress: (data: any) => void;
  loader: boolean;
}

const AbhaAddressModal: React.FC<AbhaAddressModalProps> = ({suggestions ,verifySelectedAbhaAddress,loader}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    verifySelectedAbhaAddress(data);
  };

  return (
    <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "1.25rem",
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            className="form-heading"
            sx={{
              fontWeight: "var(--neuro-font-weight-bold)",
              fontSize: "var(--neuro-font-size-large-medium)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              lineHeight: "120%",
            }}
          >
            Aadhaar
          </Typography>

          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-tiniest)",
              fontWeight: "var(--neuro-font-weight-bold)",
              marginTop: "0.75rem",
              marginBottom: isMobile ? "1.5rem" : "0.625rem",
              textAlign: "center",
            }}
          >
            ABHA (Ayushman Bharat Health Account) address is a unique username that allows you to share and access your health records digitally. It is similar to an email address, but it is only used for health records.
            To create ABHA address, it should have Min - 8 characters, Max - 18 characters, special characters allowed - 1 dot (.) and/or 1 underscore (_).
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginBlock: "1rem",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Input
              type="text"
              fullWidth
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Enter ABHA address*"
              {...register("abhaAddress")}
              error={!!errors.abhaAddress}
              helperText={errors.abhaAddress?.message}
            />

          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-extra-small)",
              fontWeight: "var(--neuro-font-weight-bold)",
              marginTop: "0.75rem",
              marginBottom: isMobile ? "1.5rem" : "0.625rem",
            }}
          >
            Suggestions  :
          </Typography>

            <Box sx={{ display: "flex",  gap: "0.5rem" , flexWrap:"wrap" }}>
          {suggestions?.map((suggestion : string, index : number) => (
            <Typography
              key={index}
              onClick={() => setValue("abhaAddress", suggestion)}
              sx={{
                fontSize: "var(--neuro-font-size-tiniest)",
                cursor: "pointer",
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              {suggestion}
            </Typography>
          ))}
        </Box>
           
          </Box>

        
          <Box
            sx={{
              display: "flex",
              mt: "2rem",
              mb: "1rem",
              alignItems: "center",
              gap: "1.25rem",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={loader}
              className="success-button"
              // onClick={handleSubmitNew}
              sx={{
                opacity:loader ? 0.75:1,
                textTransform: "none",
                width: isMobile ? "6.25rem" : "9.375rem",
                height: isMobile ? "2.5rem" : "3rem",
                color: "var(--neuro-white-text)",
              }}
            >
              {loader ? <Spinner size={"1.25rem"}  /> : "Create ABHA"}
            </Button>
          </Box>
        </Box>
    </>
  );
};

export default AbhaAddressModal;
