import { createBrowserRouter, RouteObject } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute"; // Adjust the import based on your file structure

import Dashboard from "../../components/dashboard/Index";
import AuthRedirect from "./AuthRedirect";
import ReportViewer from "../documents/view/ReportViewer";
import AppointmentConfirmation from "../appointments/AppointmentConfirmation";
import Speciality from "../appointments/Speciality";
import NewAppointment from "../appointments/NewAppointment";
import MainLayout from "../layouts/MainLayout";
import PatientProfile from "../profile/patient";
import AuthLayout from "../layouts/AuthLayout";
import LoginForm from "../auth/LoginForm";
import NotFound from "./NotFound";
import AbhaTermsAndConditions from "./AbhaTermsAndConditions";
import Documents from "../documents/list";
import MedicalHistory from "../medical-history/MedicalHistory";
import Subscriptions from "../subscriptions/Subscriptions";
import Settings from "../settings/Settings";
import Appointments from "../appointments/Appointments";
import PatientDisorder from "../appointments/NewAppointment/PatientDisorder";
import GeneralForm from "../forms/GeneralForm";
import { dataDrivenFormSchema } from "../forms/GeneralFormSchema";
import DisorderForm from "../appointments/NewAppointment/DisorderForm";
// import AppointmentScheduler from "../appointments/AppointmentScheduler";
import Checkout from "../appointments/Checkout";
import Meet from "../appointments/Meet/Meet";
import Referrals from "../referrals/Referrals";
import Patients from "../patients/Patients";
import PatientDocuments from "../patients/PatientDocuments";
import PatientForms from "../patients/PatientForms";
import PatientsProfile from "../patients/PatientsProfile";
import PatientAppointments from "../patients/PatientAppointments";
import Doctors from "../doctors/Doctors";
import Hospitals from "../hospitals/Hospitals";
import PrivateRoute from "./PrivateRoute";
import NewFlow from "../appointments/AppointmentBooking/NewFlow";
import PatientAbhaDetails from "../patients/PatientAbhaDetails";
import SingleReportViewer from "../documents/view/SingleReportViewer";
import DoctorDetails from "../doctors/DoctorDetails";
import PatientInformation from "../appointments/Appointments/PatientInformation";
// import PatientAuditLogsList from "../patients/patientAuditLogsList";
import NewPatientDetailsPage from "../patients/NewPatientDetailsPage";
import { ADMIN, CLINIC_ADMIN, DOCTOR } from "../../utils/constants";
import NewPatientDocuments from "../patients/NewPatientDocuments";
import NewPatientAppointments from "../patients/NewPatientAppointments";
import NewPatinetForms from "../patients/NewPatinetForms";
import NewPatientAbhaDetails from "../patients/NewPatientAbhaDetails";
import NewPatientAuditLogList from "../patients/NewPatientAuditLogList";
import DoctorPersonalInfoFormPage from "../doctors/DoctorPersonalInfoFormPage";
import DoctorAvailabilityFormPage from "../doctors/DoctorAvailabilityFormPage";
import DoctorPaymentSettingsFormPage from "../doctors/DoctorPaymentSettingsFormPage";

import FormViewComponent from "../FormsCreator/FormviewComponent";
import DoctorProfessionalProfileFormPage from "../doctors/DoctorProfessionalProfileFormPage";
import DoctorWorkExperienceFormPage from "../doctors/DoctorWorkExperienceFormPage";
import DoctorAcademicQualificationsFormPage from "../doctors/DoctorAcademicQualificationsFormPage";
import DoctorLeaveSettingsFormPage from "../doctors/DoctorLeaveSettingsFormPage";
import FormPersonalInfoFormPage from "../FormsCreator/FormPersonalInfoFormPage";
import FormCreator from "../FormsCreator/FormCreator";
import FormsCreateViewAndEdit from "../FormsCreator/FormsCreateViewAndEdit";
import FormCreatorViewEditUsinId from "../FormsCreator/FormCreatorViewEditUsinId";

const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthRedirect
        element={
          <AuthLayout>
            <LoginForm />
          </AuthLayout>
        }
      />
    ),
  },
  {
    path: "/abha-terms-and-conditons",
    element: <AbhaTermsAndConditions />,
  },
  {
    path: "/not-authorized",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const protectedRoutes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Dashboard />} />,
  },
  {
    path: "/documents",
    element: <PrivateRoute element={<Documents />} />,
  },
  {
    path: "/documents/view",
    element: <PrivateRoute element={<ReportViewer />} />,
  },
  {
    path: "/documents/:id/details",
    element: <PrivateRoute element={<SingleReportViewer />} />,
  },
  {
    path: "/medical-history",
    element: <PrivateRoute element={<MedicalHistory />} />,
  },
  {
    path: "/subscriptions",
    element: <PrivateRoute element={<Subscriptions />} />,
  },
  {
    path: "/settings",
    element: <PrivateRoute element={<Settings />} />,
  },
  {
    path: "/doctors/personal-profile",
    element: <PrivateRoute element={<DoctorPersonalInfoFormPage />} />,
  },
  {
    path: "/formPersonalInfoFormPage",
    element: <PrivateRoute element={<FormPersonalInfoFormPage />} />,
  },


  {
    path: "/doctors/:id/consultation-settings",
    element: <PrivateRoute element={<DoctorAvailabilityFormPage />} />,
  },
  {
    path: "/doctors/:id/payment-settings",
    element: <PrivateRoute element={<DoctorPaymentSettingsFormPage />} />,
  },
  {
    path: "/doctors/:id/professional-profile",
    element: <PrivateRoute element={<DoctorProfessionalProfileFormPage />} />,
  },
  {
    path: "/doctors/:id/work-experience",
    element: <PrivateRoute element={<DoctorWorkExperienceFormPage />} />,
  },
  {
    path: "/doctors/:id/academic-qualifications",
    element: <PrivateRoute element={<DoctorAcademicQualificationsFormPage />} />,
  },
  {
    path: "/doctors/:id/leave-settings",
    element: <PrivateRoute element={<DoctorLeaveSettingsFormPage />} />,
  },
  {
    path: "/appointments",
    element: <PrivateRoute element={<Appointments />} />,
  },
  {
    path: "/appointments/patient",
    element: <PrivateRoute element={<PatientInformation />} />,
    children: [
      {
        path: ":id/documents",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={["DOCTOR"]}
          />
        ),
        children: [
          {
            path: ":id/details",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<SingleReportViewer />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
          {
            path: "view",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<ReportViewer />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
        ],
      },
      {
        path: ":id/forms",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientForms />} />}
            allowedRoles={["DOCTOR"]}
          />
        ),
      },
      {
        path: ":id/abha-details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientAbhaDetails />} />}
            allowedRoles={["DOCTOR"]}
          />
        ),
      },
      {
        path: ":id/profile",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientsProfile />} />}
            allowedRoles={["DOCTOR", "ADMIN"]}
          />
        ),
      },
      {
        path: ":id/appointments",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientAppointments />} />}
            allowedRoles={["DOCTOR"]}
          />
        ),
        children: [
          {
            path: "specialties",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<Speciality />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
          {
            path: "book",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<NewFlow />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
          {
            path: "specialties/:specialisation",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<NewFlow />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
          {
            path: ":id/details",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<AppointmentConfirmation />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
        ],
      },
    ],
  },

  {
    path: "/appointments/new/patient-disorder",
    element: <PrivateRoute element={<PatientDisorder />} />,
  },
  {
    path: "/appointments/new/forms/general-form",
    element: (
      <PrivateRoute
        element={<GeneralForm formSchema={dataDrivenFormSchema} />}
      />
    ),
  },
  {
    path: "/appointments/new/forms/:disorder",
    element: <PrivateRoute element={<DisorderForm />} />,
  },
  // {
  //   path: "/appointments/new-appointment/appointment-scheduler",
  //   element: <PrivateRoute element={<AppointmentScheduler />} />,
  // },
  {
    path: "/appointments/new-appointment/checkout",
    element: <PrivateRoute element={<Checkout />} />,
  },
  {
    path: "/appointment/:id",
    element: <PrivateRoute element={<AppointmentConfirmation />} />,
  },
  {
    path: "/specialties",
    element: <PrivateRoute element={<Speciality />} />,
  },
  {
    path: "/specialties/:id",
    element: <PrivateRoute element={<NewAppointment />} />,
  },
  {
    path: "/meet/:appointmentId",
    element: <PrivateRoute element={<Meet />} />,
    // children: [
    //   {
    //     path: "profile",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    //   {
    //     path: "documents",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    //   {
    //     path: "appointments",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //     children: [
    //       {
    //         path: ":id/details",
    //         element: (
    //           <RoleBasedRoute
    //             element={<PrivateRoute element={<AppointmentConfirmation />} />}
    //             allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
    //           />
    //         ),
    //       },
    //     ]
    //   },
    //   {
    //     path: "forms",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    //   {
    //     path: "abha-details",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    //   {
    //     path: "doctor-notes",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    //   {
    //     path: "medication",
    //     element: (
    //       <RoleBasedRoute
    //         element={<PrivateRoute element={<PatientDocuments />} />}
    //         allowedRoles={[DOCTOR]}
    //       />
    //     ),
    //   },
    // ]
  },
  {
    path: "/referrals",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<Referrals />} />}
        allowedRoles={["ADMIN", "NETWORK", "CLINIC_ADMIN"]}
      />
    ),
  },
  {
    path: "/patients",
    element: <PrivateRoute element={<Patients />} />,
    children: [
      // {
      //   path: ":id/documents",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<PatientDocuments />} />}
      //       allowedRoles={["ADMIN"]}
      //     />
      //   ),
      //   children: [
      //     {
      //       path: ":id/details",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<SingleReportViewer />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //     {
      //       path: "view",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<ReportViewer />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //   ],
      // },
      // {
      //   path: ":id/forms",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<PatientForms />} />}
      //       allowedRoles={["ADMIN"]}
      //     />
      //   ),
      // },
      // {
      //   path: ":id/abha-details",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<PatientAbhaDetails />} />}
      //       allowedRoles={["ADMIN"]}
      //     />
      //   ),
      // },
      // {
      //   path: ":id/profile",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<NewPatientDetailsPage />} />}
      //       // element={<PrivateRoute element={<PatientsProfile />} />}
      //       allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      //     />
      //   ),
      // },
      // {
      //   path: ":id/appointments",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<PatientAppointments />} />}
      //       allowedRoles={["ADMIN"]}
      //     />
      //   ),
      //   children: [
      //     {
      //       path: "specialties",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<Speciality />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //     {
      //       path: "book",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<NewFlow />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //     {
      //       path: "specialties/:specialisation",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<NewFlow />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //     {
      //       path: ":id/details",
      //       element: (
      //         <RoleBasedRoute
      //           element={<PrivateRoute element={<AppointmentConfirmation />} />}
      //           allowedRoles={["ADMIN"]}
      //         />
      //       ),
      //     },
      //   ],
      // },
      // {
      //   path: ":id/audit-logs",
      //   element: (
      //     <RoleBasedRoute
      //       element={<PrivateRoute element={<PatientAuditLogsList />} />}
      //       allowedRoles={["ADMIN"]}
      //     />
      //   ),
      // },
    ],
  },
  {
    path: "/patients/:id/profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientDetailsPage />} />}
        // element={<PrivateRoute element={<PatientsProfile />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/appointments",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAppointments />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
    children: [
      {
        path: "specialties",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<Speciality />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "book",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewFlow />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "specialties/:specialisation",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewFlow />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: ":id/details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<AppointmentConfirmation />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
    ],
  },
  {
    path: "/patients/:id/documents",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientDocuments />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
    children: [
      {
        path: ":id/details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<SingleReportViewer />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "view",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<ReportViewer />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
    ],
  },
  {
    path: "/patients/:id/forms",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatinetForms />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/abha-details",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAbhaDetails />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/audit-logs",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAuditLogList />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN]}
      />
    ),
  },
  {
    path: "/doctors",
    element: <PrivateRoute element={<Doctors />} />,
  },
  {
    path: "/doctors/:id/details",
    element: <PrivateRoute element={<DoctorDetails />} />,
  },

  {
    path: "/forms",
    element: <PrivateRoute element={<FormCreator />} />,
  },
  {
    path: "forms/new",
    element: <PrivateRoute element={<FormsCreateViewAndEdit />} />,
  },
  {
    path: "/forms/:id",
    element: <PrivateRoute element={<FormCreator />} />,
  },
  {
    path: "/forms/:id/settings",
    element: <PrivateRoute element={<FormCreatorViewEditUsinId />} />,
  },

  // {
  //   path: "/doctors/personal-info",
  //   element: <PrivateRoute element={<DoctorPersonalInfoFormPage />} />,
  // },
  {
    path: "/forms/:id/create",
    element: <PrivateRoute element={<FormViewComponent />} />,
  },

  {
    path: "/clinics",
    element: <PrivateRoute element={<Hospitals />} />,
  },
  {
    path: "/profile/personal-info",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
  {
    path: "/profile/forms",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
  {
    path: "/profile/abha-details",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
];

export const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: protectedRoutes,
  },
  ...publicRoutes,
]);
