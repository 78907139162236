import React, { useEffect, useState } from "react";
import { Box, List } from "@mui/material";
import { SidebarMenuProps } from "../../types/common";
import SidebarSubmenuItem from "../ui/SidebarSubmenuItem";
import FormViewSidebar from "./FormViewSidebar";
import { useLocation } from "react-router-dom";

const SidebarSubMenu: React.FC<SidebarMenuProps> = ({
  menuItems,
  showIconsOnly = false,
  usedInSubMenu = false,
}) => {

  const [selectedItem, setSelectedItem] = useState<any>(null);

  // const userId =localStorage.getItem("formid");
  const handleMenuToggle = (isExpanded: any) => {
    console.log('Sidebar toggled:', isExpanded);
  };
  const location = useLocation();
  useEffect(() => {

    const urlParams = new URLSearchParams(location.search);
    console.log("urlParams1111", urlParams);
  }, [location.search])



  const url = window.location.href;
  console.log('Sidebar toggled url:', url);

  // Use a regular expression to capture the userId
  const regex = /\/forms\/([a-zA-Z0-9]+)\/create/;

  // Match the URL with the regex
  const match = url.match(regex);

  // Extract the userId (the second capture group)
  const userId1 = match ? match[1] : ""; // If matched, userId will be the first captured group

  console.log("userId1", userId1);

  const urlParams = new URLSearchParams(location.search);
  console.log("urlParams", urlParams);

  // Handle item selection
  const handleItemSelect = (item: any) => {
    setSelectedItem(item);
    console.log('Selected item:', item);
  };



  return (
    <List
      sx={{
        padding: "0.825rem 0rem 0rem 0.225rem",
        height: "100%",
        overflow: "auto",
      }}
    >
      {menuItems.map((item, index) => (
        <>
          <Box key={index}>
            <SidebarSubmenuItem
              text={item.text}
              icon={item.icon}
              route={item.route}
              selected={item.selected}
              hasSubmenus={!!item.submenus && item.submenus.length > 0}
              submenus={item.submenus}
              initiallyOpen
              showIconsOnly={showIconsOnly}
              usedInSubMenu={usedInSubMenu}
              disabled={item?.disabled || false}
            />


          </Box>
          {/* {(!!location.pathname.match(
              /^\/forms\/[a-zA-Z0-9]+\/create$/
            )|| !!location.pathname.match(
              /^\/forms\/[a-zA-Z0-9]+\/settings$/
            )) &&
            userId1 !== "" ? <>  <React.Fragment key={index}>
              <FormViewSidebar
                onMenuToggle={handleMenuToggle}
                onItemSelect={handleItemSelect}
                selectedItem={selectedItem}
              />
            </React.Fragment></> : <></>} */}

        </>
      ))}


      {menuItems.map((_, index) => (
        <>
          {/* Only render FormViewSidebar for the second item (index 1) */}
          {(index === 1 &&
            !!location.pathname.match(/^\/forms\/[a-zA-Z0-9]+\/create$/) ||
            !!location.pathname.match(/^\/forms\/[a-zA-Z0-9]+\/settings$/)
          ) && userId1 !== "" ? (
            <React.Fragment key={index}>
              <FormViewSidebar
                onMenuToggle={handleMenuToggle}
                onItemSelect={handleItemSelect}
                selectedItem={selectedItem}
              />
            </React.Fragment>
          ) : null}


        </>
      ))}



    </List>
  );
};

export default SidebarSubMenu;
