import React, { useCallback, useState } from 'react';
import { FaCopy, FaTrash, FaStar, FaRegStar } from 'react-icons/fa';
// import './compo2.css';

interface FileInputProps {
  question: {
    type: string;
    name: string;
    title: string;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  updateQuestionTitle: (updatedTitle: string) => void; // For updating the title
}

const FileInput: React.FC<FileInputProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingName, _] = useState(question.name);
  const [editingTitle, setEditingTitle] = useState(question.title); // Added state for title
  const [isRequired, setIsRequired] = useState(false);

  // Handle file change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // Handle removing file
  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const syncWithParent = useCallback(() => {
    updateQuestion(index, { ...localQuestion, name: editingName, title: editingTitle });  // Include title in update
  }, [index, localQuestion, editingName, editingTitle, updateQuestion]);


  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setEditingTitle(newTitle);
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
    syncWithParent(); // Sync with parent
  };

  // Toggle required state
  const handleToggleRequired = () => {
    setIsRequired((prev) => !prev);
    toggleRequired(index);
  };

  return (
    <div className="question-container">
      <div className="question-header" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* Editable question title */}
        {editingName} <input
          type="text"
          value={editingTitle}  // Bind the title input to editingTitle state
          onChange={handleTitleChange}  // Handle title change
          className="question-name-input"
          aria-label="Question Title"
        />

        {/* Editable question name */}
       
        
        {isRequired && <span className="required">*</span>}
      </div>

      <div className="file-input-container">
        <label htmlFor={`file-${index}`} className="file-upload-label">
          {selectedFile ? (
            <span className="file-name">{selectedFile.name}</span>
          ) : (
            <span className="file-placeholder">
              Drag and drop a file here or click the button below to select a file to upload.
            </span>
          )}
        </label>
        <input
          type="file"
          id={`file-${index}`}
          onChange={handleFileChange}
          className="file-input"
        />
        {selectedFile && (
          <button className="remove-file-button" onClick={handleRemoveFile}>
            Remove File
          </button>
        )}
      </div>

      <div className="question-controls">
        {/* Duplicate Button */}
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaCopy style={{ color: 'orange' }} /> Duplicate
        </button>

        {/* Delete Button */}
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash style={{ color: 'orange' }} /> Delete
        </button>

        {/* Required/Optional Toggle Button */}
        <button
          className={`required-button ${isRequired ? 'active' : ''}`}
          onClick={handleToggleRequired}
        >
          {isRequired ? <FaStar style={{ color: 'orange' }} /> : <FaRegStar style={{ color: 'orange' }} />}
          {isRequired ? 'Required' : 'Optional'}
        </button>
      </div>
    </div>
  );
};

export default FileInput;
