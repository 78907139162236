import React, { useCallback, useEffect, useState } from "react";
import {
  deleteReferral,
  updateReferralStatus,
} from "../../services/referralService";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "../shared/ConfirmDialog";
import CommentModal from "./CommentModal";
import { Referral } from "../../types/common";
import useAuthStore from "../../store/authStore";
import ComponentHeader from "../shared/ComponentHeader";
import ContentCenter from "../shared/ContentCenter";
import LoadingScreen from "../shared/LoadingScreen";
import { ReferralsListProps } from "../../types/referrals";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import { Option } from "../../types/appointments";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import {
  isAdminOrClinicAdminOrNetwork,
  isClinicAdminOrAdmin,
  isNetwork,
} from "../../utils/auth";
import ReferralsTableActions from "./ReferralsTableActions";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
import ReferralCard from "./ReferralCard";
import AddEditReferralPage from "./AddEditReferralPage";
// import config from "../../config";

const ReferralsList: React.FC<ReferralsListProps> = ({
  referrals,
  loading,
  loadReferrals,
  redirectedFrom = "",
}) => {
  // props & state values
  const { referralsFilter, setFilter, showSnackbar } = useAppStore();

  console.log("referrals in list", referrals);

  const { userRole } = useAuthStore();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [commentStatus, setCommentStatus] = useState<"Accepted" | "Rejected">(
    "Accepted"
  );

  const [showList, setShowList] = useState(true);

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("referralsView") || "table"
  );

  const [open, setOpen] = useState(false);

  const options: Option[] = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Cancelled" },
  ];

  const [selectedReferral, setSelectedReferral] = useState<Referral | null>(
    null
  );
  const [deleteReferralId, setDeleteReferralId] = useState<string | null>(null);
  const [editReferral, setEditReferral] = useState<Referral | null>(null);

  console.log("editReferral in list", editReferral);

  const [anchorElByRow, setAnchorElByRow] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  // callbacks & functions
  useEffect(() => {
    if (isClinicAdminOrAdmin(userRole) && redirectedFrom !== "dashboard") {
      setFilter("referralsFilter", {
        status: "Pending",
      });
    }
  }, [userRole, redirectedFrom]);

  const getUpdatedReferralsAfterAddEdit = () => {
    loadReferrals(
      referralsFilter.status || "",
      referralsFilter.customStartDate,
      referralsFilter.customEndDate,
      referralsFilter?.searchValue || ""
    );
  };

  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    loadReferrals(
      filter.status || "",
      filter.customStartDate,
      filter.customEndDate,
      value
    );
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      handleInputChange(value, filter);
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("referralsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, referralsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all appointments
    //   handleInputChange("", referralsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, referralsFilter);
    // }
  };
  // search filter handlers end

  const getStatusIcon = (status: string) => {
    if (status === "Accepted") {
      return (
        <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
      );
    } else if (status === "Pending") {
      return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
    } else if (status === "Rejected") {
      return <NotInterestedIcon sx={{ color: "var(--neuro-svg-error)" }} />;
    } else {
      return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
    }
  };

  const showContactNumber = (number: string | null | undefined) => {
    if (!number || number === "") {
      return "-";
    } else {
      if (number.includes("+")) {
        return number;
      } else {
        return `+91${number}`;
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddNew = () => {
    setEditReferral(null);
    setShowList(false);
  };

  const handleEdit = (referral: Referral) => {
    setEditReferral(referral);
    setShowList(false);
  };

  const handleDelete = async (id: string) => {
    setDeleteReferralId(id);
    setConfirmOpen(true);
  };

  const handleEditReferralByActions = (row: any) => {
    handleEdit(row);
  };

  const handleDeleteReferralByActions = (id: string) => {
    if (id && id !== "") {
      handleDelete(id);
    }
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteReferralId) {
      try {
        await deleteReferral(deleteReferralId);
        loadReferrals(
          referralsFilter.status || "",
          referralsFilter.customStartDate,
          referralsFilter.customEndDate,
          referralsFilter?.searchValue || ""
        );
      } catch (error) {
        console.error("Error deleting referral:", error);
      }
    }
    setDeleteReferralId(null);
  };

  const handleCommentModalClose = () => {
    setSelectedReferral(null);
    setCommentModalOpen(false);
  };

  const handleCommentSubmit = async (comments: string | null) => {
    if (selectedReferral) {
      try {
        await updateReferralStatus(
          selectedReferral.id,
          commentStatus,
          comments
        );
        loadReferrals(
          referralsFilter.status || "",
          referralsFilter.customStartDate,
          referralsFilter.customEndDate,
          referralsFilter?.searchValue || ""
        );
      } catch (error) {
        showSnackbar(
          `Could not ${commentStatus === "Accepted" ? "Accept" : "Reject"} the referral, please try again`,
          "error"
        );
        console.error(`Error updating referral to ${commentStatus}:`, error);
      }
    }
    setSelectedReferral(null);
    setCommentModalOpen(false);
  };

  const openCommentModal = (
    referral: Referral,
    status: "Accepted" | "Rejected"
  ) => {
    setSelectedReferral(referral);
    setCommentStatus(status);
    setCommentModalOpen(true);
  };

  const handleAcceptReferralByActions = (row: any) => {
    openCommentModal(row, "Accepted");
  };

  const handleRejectReferralByActions = (row: any) => {
    openCommentModal(row, "Rejected");
  };

  const updateReferralsStausFilter = (value: string) => {
    setFilter("referralsFilter", {
      status: value,
    });
  };

  const updateReferralsRangeFilter = (value: string) => {
    setFilter("referralsFilter", {
      range: value,
    });
  };

  const updateReferralsCustomDateFilter = (start: string, end: string) => {
    setFilter("referralsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  const handleAcceptReferral = (referral: any) => {
    openCommentModal(referral, "Accepted");
  };

  const handleCancelReferral = (referral: any) => {
    openCommentModal(referral, "Rejected");
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId: string) => {
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: null }));
  };

  // all referrals table columns
  const allRefferalsTableColumns = [
    {
      id: "firstName",
      label: "Patient Name",
      sortable: true,
      render: (value: any) => `${capitalizeFirstLetter(value || "") || "-"}`,
    },
    {
      id: "mobile",
      label: "Mobile",
      sortable: true,
      render: (value: any) => `${showContactNumber(value || "") || "-"}`,
    },
    {
      id: "referralDoctorFirstName",
      label: "Referred To",
      sortComparator: (a: any, b: any) => {
        const aReferredTo = a?.referralDoctorFirstName || "";
        const bReferredTo = b?.referralDoctorFirstName || "";

        // Move empty or null values to the end
        if (aReferredTo === "") return 1;
        if (bReferredTo === "") return -1;

        // Normal locale comparison for non-empty values
        return aReferredTo.localeCompare(bReferredTo);
      },
      render: (value: any) => `${value || "-"}`,
      sortable: true,
    },
    {
      id: "doctor.user.firstName",
      label: "Referred By",
      sortComparator: (a: any, b: any) => {
        const aReferredBy = a?.doctor?.user?.firstName || "";
        const bReferredBy = b?.doctor?.user?.firstName || "";

        // Move empty or null values to the end
        if (aReferredBy === "") return 1;
        if (bReferredBy === "") return -1;

        // Normal locale comparison for non-empty values
        return aReferredBy.localeCompare(bReferredBy);
      },
      render: (_: any, row: Referral) =>
        `${row?.doctor?.user?.firstName || "-"}`,
      sortable: true,
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => `${getFormattedDate(value || "") || "-"}`,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      render: (value: any) => getStatusIcon(value || ""),
    },
    ...(isAdminOrClinicAdminOrNetwork(userRole) &&
    referrals.some((row) => row?.status === "Pending")
      ? [
          {
            id: "actions",
            label: "Actions",
            render: (_: any, row: Referral): any => (
              <Box>
                {isNetwork(userRole) && row?.status === "Pending" && (
                  <ReferralsTableActions
                    handleMenuClose={() => handleMenuClose(row.id)}
                    handleMenuOpen={(event: any) =>
                      handleMenuOpen(event, row.id)
                    }
                    anchorEl={anchorElByRow[row.id] || null}
                    row={row}
                    key={row?.id}
                    showNetworkActions
                    handleEdit={handleEditReferralByActions}
                    handleDelete={handleDeleteReferralByActions}
                  />
                )}

                {isClinicAdminOrAdmin(userRole) &&
                  row?.status === "Pending" && (
                    <ReferralsTableActions
                      handleMenuClose={() => handleMenuClose(row.id)}
                      handleMenuOpen={(event: any) =>
                        handleMenuOpen(event, row.id)
                      }
                      anchorEl={anchorElByRow[row.id] || null}
                      row={row}
                      key={row?.id}
                      handleAccept={handleAcceptReferralByActions}
                      handleCancel={handleRejectReferralByActions}
                    />
                  )}

                {isAdminOrClinicAdminOrNetwork(userRole) &&
                  referrals.some((row) => row?.status === "Pending") &&
                  row?.status !== "Pending" && (
                    <Box
                      sx={{
                        width: "55%",
                        textAlign: "center",
                      }}
                    >
                      -
                    </Box>
                  )}
              </Box>
            ),
          },
        ]
      : []),
  ];

  // filter referrals table columns
  const filterRefferalsTableColumns = [
    {
      id: "firstName",
      label: "Patient Name",
      sortable: true,
      render: (value: any) => `${capitalizeFirstLetter(value || "") || "-"}`,
    },
    {
      id: "mobile",
      label: "Mobile",
      sortable: true,
      render: (value: any) => `${showContactNumber(value || "") || "-"}`,
    },
    {
      id: "urgencyOfReferral",
      label: "Urgency",
      render: (_: any, row: Referral) => `${row?.urgencyOfReferral || "-"}`,
      sortable: true,
    },
    {
      id: "referralDoctorFirstName",
      label: "Referred To",
      sortComparator: (a: any, b: any) => {
        const aReferredTo = a?.referralDoctorFirstName || "";
        const bReferredTo = b?.referralDoctorFirstName || "";

        // Move empty or null values to the end
        if (aReferredTo === "") return 1;
        if (bReferredTo === "") return -1;

        // Normal locale comparison for non-empty values
        return aReferredTo.localeCompare(bReferredTo);
      },
      render: (value: any) => `${value || "-"}`,
      sortable: true,
    },
    {
      id: "doctor.user.firstName",
      label: "Referred By",
      sortComparator: (a: any, b: any) => {
        const aReferredBy = a?.doctor?.user?.firstName || "";
        const bReferredBy = b?.doctor?.user?.firstName || "";

        // Move empty or null values to the end
        if (aReferredBy === "") return 1;
        if (bReferredBy === "") return -1;

        // Normal locale comparison for non-empty values
        return aReferredBy.localeCompare(bReferredBy);
      },
      render: (_: any, row: Referral) =>
        `${row?.doctor?.user?.firstName || "-"}`,
      sortable: true,
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => `${getFormattedDate(value || "") || "-"}`,
    },
    {
      id: "status",
      label: "Status",
      render: (value: any) => getStatusIcon(value || ""),
    },

    ...(isAdminOrClinicAdminOrNetwork(userRole) &&
    referralsFilter.status === "Pending"
      ? [
          {
            id: "actions",
            label: "Actions",
            render: (_: any, row: Referral): any => (
              <>
                {isNetwork(userRole) &&
                  referralsFilter.status === "Pending" && (
                    <ReferralsTableActions
                      handleMenuClose={() => handleMenuClose(row.id)}
                      handleMenuOpen={(event: any) =>
                        handleMenuOpen(event, row.id)
                      }
                      anchorEl={anchorElByRow[row.id] || null}
                      row={row}
                      key={row?.id}
                      showNetworkActions
                      handleEdit={handleEditReferralByActions}
                      handleDelete={handleDeleteReferralByActions}
                    />
                  )}

                {isClinicAdminOrAdmin(userRole) &&
                  referralsFilter.status === "Pending" && (
                    <ReferralsTableActions
                      handleMenuClose={() => handleMenuClose(row.id)}
                      handleMenuOpen={(event: any) =>
                        handleMenuOpen(event, row.id)
                      }
                      anchorEl={anchorElByRow[row.id] || null}
                      row={row}
                      key={row?.id}
                      handleAccept={handleAcceptReferralByActions}
                      handleCancel={handleRejectReferralByActions}
                    />
                  )}
              </>
            ),
          },
        ]
      : []),
  ];

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("referralsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        minHeight: "100%",
        // paddingInline: "20px",
        // mb: 2,
        height: "100%",
      }}
    >
      {showList && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <ComponentHeader title="Referral List" />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              {isNetwork(userRole) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="outlined-secondary-button"
                  startIcon={<AddIcon />}
                  onClick={handleAddNew}
                >
                  Add New
                </Button>
              )}
            </Box>
          </Box>

          <Box sx={{ marginBottom: "1rem" }}>
            <ComponentFilter
              filterByCategoryOpen={open}
              filterByCategoryValue={referralsFilter.status || ""}
              handleFilterByCategoryValue={updateReferralsStausFilter}
              handleFilterByCategoryClose={handleClose}
              handleFilterByCategoryOpen={handleOpen}
              filterByCategoryOptions={options}
              updateRange={updateReferralsRangeFilter}
              updateDates={updateReferralsCustomDateFilter}
              range={referralsFilter.range}
              isEmpty
              viewComponent={
                <CardTableToggle view={view} setView={handleToggleView} />
              }
              handleStoreSearchValueChange={handleStoreSearchValueChange}
              searchValue={referralsFilter?.searchValue || ""}
              searchFilterPlaceholder="Patient/Doctor Name, Mobile, Email"
              searchFilterWidth="20rem"
            />
          </Box>

          <Box
            sx={{
              height: isNetwork(userRole)
                ? "calc(100% - 8rem)"
                : "calc(100% - 7rem)",
            }}
          >
            {loading ? (
              <ContentCenter>
                <LoadingScreen />
              </ContentCenter>
            ) : referrals && referrals?.length > 0 ? (
              referralsFilter.status === "All" ? (
                view === "card" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      maxHeight: "100%",
                      overflow: "auto",
                    }}
                  >
                    {referrals?.map((referral) => (
                      <ReferralCard
                        key={referral.id}
                        referral={referral}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleAccept={handleAcceptReferral}
                        handleCancel={handleCancelReferral}
                      />
                    ))}
                  </Box>
                ) : (
                  <TableWrapper
                    columns={allRefferalsTableColumns}
                    rows={referrals}
                    stickyHeader={true}
                    maxHeight="100%"
                  />
                )
              ) : view === "card" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    maxHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  {referrals?.map((referral) => (
                    <ReferralCard
                      key={referral.id}
                      referral={referral}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleAccept={handleAcceptReferral}
                      handleCancel={handleCancelReferral}
                    />
                  ))}
                </Box>
              ) : (
                <TableWrapper
                  columns={filterRefferalsTableColumns}
                  rows={referrals}
                  stickyHeader={true}
                  maxHeight="100%"
                />
              )
            ) : (
              <ContentCenter>
                <Typography className="no-data">No referrals found</Typography>
              </ContentCenter>
            )}
          </Box>
        </>
      )}

      {!showList && (
        <AddEditReferralPage
          referral={editReferral}
          loadReferrals={getUpdatedReferralsAfterAddEdit}
          onClose={() => setShowList(true)}
        />
      )}

      <ConfirmDialog
        open={confirmOpen}
        title="Confirm Delete"
        message="Are you sure you want to delete this referral?"
        onClose={handleConfirmClose}
      />

      <CommentModal
        open={commentModalOpen}
        status={commentStatus}
        onClose={handleCommentModalClose}
        onSubmit={handleCommentSubmit}
      />
    </Box>
  );
};

export default ReferralsList;
