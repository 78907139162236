import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);

export function capitalizeFirstLetter(string: string) {
  if (!string || string === "") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWordsPreservingSpaces(string: string) {
  if (string === undefined || string.includes("undefined")) {
    return "";
  }
  if (string === "") {
    return "";
  }
  return string
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back together with spaces
}

export const getFormattedDate = (date: Date | string) => {
  if (!date || date === "") return "-";

  // Convert date to Date object if it's a string
  const dateObj = new Date(date);

  // Ensure the date is valid
  if (isNaN(dateObj.getTime())) return "-";

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const isValidAadhar = (input: string): boolean => {
  const aadharRegex = /^\d{12}$/;
  return aadharRegex.test(input);
};

export const isValidAbha = (input: string): boolean => {
  const abhaRegex = /^[0-9]{14}$/;
  return abhaRegex.test(input);
};

export const formatMinutesToHHMM = (totalMinutes: number): string => {
  if (!totalMinutes) {
    return "";
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

export const countryCode = [
  {
    id: "1",
    value: "+91",
    country: "India",
  },
];

export const reasonForReferrals = [
  { label: "Headache or Migrane", value: "HeadacheOrMigrane" },
  { label: "Seizures or Epilepsy", value: "SeizuresOrEpilepsy" },
  { label: "Movement Disorders", value: "MovementDisorders" },
  { label: "Neuropathy", value: "Neuropathy" },
  { label: "Cognitive Issues", value: "CognitiveIssues" },
  { label: "Others", value: "Others" },
];

export const urgencyOfReferralOptions = [
  { label: "Routine", value: "Routine" },
  { label: "Urgent", value: "Urgent" },
  { label: "Emergency", value: "Emergency" },
];

export const supportedServices = [
  { label: "Online Consultation", value: "ONLINE" },
  { label: "Clinic Visit", value: "IN_PERSON" },
];

export const departments = [
  { label: "Clinical Services", value: "CLINIC_SERVICES" },
  { label: "Research and Administration", value: "RESEARCH_AND_ADMINISTRATION" },
  { label: "Diagnostic and Support Services", value: "DIAGNOSTIC_AND_SUPPORT_SERVICES" },
  { label: " Others", value: "OTHERS" },
];

export const typeOfGraduation = [
  { label: "Graduation", value: "Graduation" },
  { label: "Post Graduation", value: "Post Graduation" },
  { label: "Super Specialisation", value: "Super Specialisation" },
  { label: "Post Doctoral Fellowship", value: "Post Doctoral Fellowship" },
];

export const specializations = [
  {
    id: "1",
    name: "NEUROLOGIST",
  },
  {
    id: "2",
    name: "PRIMARY_CARE",
  },
  {
    id: "3",
    name: "PEDIATRICS",
  },
];



export const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export const getStartAndEndDateTimesForRange = (range: string) => {
  const currentTime = dayjs().tz("Asia/Kolkata");
  let start;
  let end;

  switch (range) {
    case "TODAY":
      start = currentTime.startOf("day").utc().format();
      end = currentTime.endOf("day").utc().format();
      break;
    case "YESTERDAY":
      start = currentTime.subtract(1, "day").startOf("day").utc().format();
      end = currentTime.subtract(1, "day").endOf("day").utc().format();
      break;
    case "THIS_WEEK":
      start = currentTime.weekday(1).startOf("day").utc().format(); // Monday as start of the week
      end = currentTime.weekday(7).endOf("day").utc().format(); // Sunday as end of the week
      break;
    case "LAST_WEEK":
      start = currentTime
        .weekday(1)
        .subtract(1, "week")
        .startOf("day")
        .utc()
        .format(); // Last week's Monday
      end = currentTime
        .weekday(7)
        .subtract(1, "week")
        .endOf("day")
        .utc()
        .format(); // Last week's Sunday
      break;
    case "THIS_MONTH":
      start = currentTime.startOf("month").utc().format();
      end = currentTime.endOf("month").utc().format();
      break;
    case "LAST_MONTH":
      start = currentTime.subtract(1, "month").startOf("month").utc().format();
      end = currentTime.subtract(1, "month").endOf("month").utc().format();
      break;
    case "THIS_YEAR":
      start = currentTime.startOf("year").utc().format();
      end = currentTime.endOf("year").utc().format();
      break;
    case "LAST_YEAR":
      start = currentTime.subtract(1, "year").startOf("year").utc().format();
      end = currentTime.subtract(1, "year").endOf("year").utc().format();
      break;
    default:
      start = end = "";
  }

  return { start, end };
};
