import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import ComponentHeader from "../../shared/ComponentHeader";
import DocumentsSection from "./DocumentsSection";
import useAuthStore from "../../../store/authStore";
import Alert from "../../ui/Alert";
import UploadIcon from "../../svg/UploadIcon";
import UploadFileModal from "../view/UploadFileModal";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";
import Button from "../../ui/Button";
import CardTableThumbnailToggle from "../../shared/CardTableThumbnailToggle";
import ComponentFilter from "../../shared/ComponentFilter";
import { debounce } from "lodash";
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../../utils/common";
// import config from "../../../config";

const Documents: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
  // props & state values
  const {
    isMenuCollapsed,
    showSnackbar,
    documentsFilter,
    setFilter,
    selectedPatientInList,
  } = useAppStore();
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const { user } = useAuthStore();

  const {
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    loadingDeleteDocument,
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    cleanUpUploadDocumentsState,
    cleanUpSelectedDocumentId,
  } = useDocumentStore();

  const [selectedOption, setSelectedOption] = useState<string>("Uploaded Date");

  const sortOptions = ["Uploaded Date", "Report Type", "Report Date"];

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("documentsView") || "table"
  );

  const sortDocuments = (docs: any[]) => {
    switch (selectedOption) {
      case "Uploaded Date":
        return [...docs]?.sort(
          (a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt)
        );
      case "Report Date":
        return [...docs].sort((a, b) => {
          if (!a?.reportDate) return -1;
          if (!b?.reportDate) return 1;
          return Date.parse(b?.reportDate) - Date.parse(a?.reportDate);
        });
      case "Report Type":
        const typeOrder: Record<string, number> = {
          AUDIO: 1,
          DICOM: 2,
          IMAGE: 3,
          PDF: 4,
          ZIP: 5,
        };
        return [...docs].sort(
          (a, b) =>
            typeOrder[a?.fileType.toUpperCase()] -
            typeOrder[b?.fileType.toUpperCase()]
        );
      default:
        return docs;
    }
  };

  const sortedDocuments =
    documents && documents.length > 0 ? sortDocuments(documents) : [];

  const categories = [
    { name: "Prescription", value: "Prescription" },
    { name: "Billing Statement", value: "Billing Statement" },
    { name: "Medical Test Results", value: "Medical Test Results" },
    { name: "Vaccination Record", value: "Vaccination Record" },
    { name: "Surgery Summary Report", value: "Surgery Summary Report" },
    { name: "Hopsital Discharge Summary", value: "Hopsital Discharge Summary" },
    { name: "Genetic Test Report", value: "Genetic Test Report" },
    { name: "Hospital Admission Summary", value: "Hospital Admission Summary" },
    { name: "Additional Documents", value: "Additional Documents" },
  ];

  const alertMessage = loadingUploadDocuments ? (
    <Alert
      sx={{ width: "100%" }}
      message="Uploading documents, please wait"
      type="info"
    />
  ) : loadingDeleteDocument ? (
    <Alert
      sx={{ width: "100%" }}
      message="Deleting document, please wait"
      type="info"
    />
  ) : null;

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
    },
    { label: "Documents" },
  ];

  // callbacks & functions
  useEffect(() => {
    cleanUpSelectedDocumentId();
  }, []);

  const getDocumentsHandler = (start: any, end: any, search: any) => {
    if (patientId) {
      fetchDocuments(patientId, start, end, search);
    } else if (!patientId) {
      fetchDocuments("", start, end, search);
    }
  };

  useEffect(() => {
    getDocumentsHandler(
      documentsFilter.customStartDate,
      documentsFilter.customEndDate,
      documentsFilter.searchValue || ""
    );
  }, [
    user?.userId,
    fetchDocuments,
    patientId,
    documentsFilter.customStartDate,
    documentsFilter.customEndDate,
  ]);

  useEffect(() => {
    if (loadingUploadDocuments) {
      // showSnackbar("Uploading documents, please wait", "info");
    } else if (errorUploadDocuments) {
      showSnackbar("Could not upload documents, please try again", "error");
      cleanUpUploadDocumentsState();
    } else if (uploadedDocuments) {
      showSnackbar("Documents Uploaded", "success");
      getDocumentsHandler(
        documentsFilter.customStartDate,
        documentsFilter.customEndDate,
        documentsFilter.searchValue || ""
      );
      cleanUpUploadDocumentsState();
    }
  }, [
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    fetchDocuments,
    patientId,
    documentsFilter.customStartDate,
    documentsFilter.customEndDate,
  ]);

  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    getDocumentsHandler(filter.customStartDate, filter.customEndDate, value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
      handleInputChange(value, filter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("documentsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, documentsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all doctors
    //   handleInputChange("", documentsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, documentsFilter);
    // }
  };
  // search filter handlers end

  const handleCancel = () => {
    setOpenUploadModal(false);
  };

  const updateDocumentsRangeFilter = (value: string) => {
    setFilter("documentsFilter", {
      range: value,
    });
  };

  const updateDocumentsCustomDateFilter = (start: string, end: string) => {
    setFilter("documentsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("documentsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingBlock: patientId && from === "meet" ? 0 : "1rem",
        paddingInline: patientId && from === "meet" ? 0 : "0.875rem",
        height: "100%",
      }}
    >
      {from === "patient-list" ? (
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <CustomBreadcrumbs items={breadcrumbItems} />
          <Button
            variant="outlined"
            className="outlined-secondary-button"
            startIcon={<UploadIcon />}
            onClick={() => setOpenUploadModal(true)}
          >
            Upload
          </Button>
        </Box>
      ) : (
        <ComponentHeader
          title={patientId ? "" : "Documents"}
          alert={alertMessage}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              className="outlined-secondary-button"
              startIcon={<UploadIcon />}
              onClick={() => setOpenUploadModal(true)}
            >
              Upload
            </Button>
          </Box>
        </ComponentHeader>
      )}
      <Box>
        <ComponentFilter
          updateRange={updateDocumentsRangeFilter}
          updateDates={updateDocumentsCustomDateFilter}
          sortOptions={sortOptions}
          selectedSortOption={selectedOption}
          setSelectedSortOption={setSelectedOption}
          sortDisabled={!documents || documents.length === 0}
          range={documentsFilter.range}
          isEmpty
          viewComponent={
            <CardTableThumbnailToggle view={view} setView={handleToggleView} />
          }
          hideStatusFilter
          showSortFilter
          handleStoreSearchValueChange={handleStoreSearchValueChange}
          searchValue={documentsFilter.searchValue || ""}
          searchFilterPlaceholder="Name, Patient/Doctor Name, Email"
          searchFilterWidth="19.7rem"
          wrap={from === "meet" ? true : false}
        />
      </Box>
      <UploadFileModal
        open={openUploadModal}
        onClose={handleCancel}
        categories={categories}
        closeIcon
        patientId={patientId}
      />
      <DocumentsSection
        isMenuCollapsed={isMenuCollapsed}
        loading={loadingDocuments}
        error={errorDocuments}
        documents={sortedDocuments}
        patientId={patientId}
        from={from}
        view={view}
      />
    </Box>
  );
};

export default Documents;
