import React, { useState } from 'react';
import { FaTrash, FaRegCopy, FaCheckCircle, FaRegCircle } from "react-icons/fa"; // Import icons
// import './rating.css'
// import './compo.css'

interface RatingGroupProps {
  question: {
    type: string;
    name: string;
    rateValues: Array<number | { value: number; text: string }>;
    required: boolean;
    title:string;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestionName: (updatedName: string) => void;
}

const DynamicRatingGroup: React.FC<RatingGroupProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestionName,
}) => {
  const [rateValues, setRateValues] = useState<Array<number | { value: number; text: string }>>(question.rateValues);
  const [editingName, setEditingName] = useState(question.name);
  const [editingTitle] = useState(question.title);
  const [isRequired, setIsRequired] = useState(question.required);

  const handleRemoveRating = () => {
    if (rateValues.length > 1) {
      setRateValues(rateValues.slice(0, -1)); // Remove the last rating
    }
  };

  const handleAddRating = () => {
    const newValue = rateValues.length + 1;
    setRateValues([...rateValues, newValue]); // Add a new rating value
  };

  const handleToggleRequired = () => {
    setIsRequired((prev) => !prev);
    toggleRequired(index);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName !== editingName) {
      setEditingName(newName);
      if (newName !== question.name) {
        updateQuestionName(newName); // Update the name immediately in the parent
      }
    }
  };

  const handleEditRating = (rateIndex: number, newValue: string) => {
    const updatedRateValues = [...rateValues];
    // Check if it's a valid number or a string, then update the value
    const updatedValue = isNaN(Number(newValue)) ? updatedRateValues[rateIndex] : { value: Number(newValue), text: '' };
    updatedRateValues[rateIndex] = updatedValue;
    setRateValues(updatedRateValues);
  };

  return (
    <div className="question-container">
      <div className="question-header">
        {editingName}
        <input
          type="text"
          value={editingTitle}
          onChange={handleNameChange}  // Handle name change
          className="question-name-input"
        />
        {isRequired && <span className="required">*</span>}
      </div>

      <div className="rating-group">
        <div className="add-remove-controls">
          <button className="remove-button" onClick={handleRemoveRating}>
            -
          </button>
          <button className="add-button" onClick={handleAddRating}>
            +
          </button>
        </div>

        <div className="rating-values">
          {rateValues.map((rateValue, rateIndex) => (
            <div key={rateIndex} className="rating-item">
              <input
                type="number"
                value={typeof rateValue === 'number' ? rateValue : rateValue.value}
                onChange={(e) => handleEditRating(rateIndex, e.target.value)} // Handle editable rating
                className="rating-input"
              />
              {/* {typeof rateValue !== 'number' && rateValue.text && (
                <span className="rating-text">{rateValue.text}</span>
              )} */}
            </div>
          ))}
        </div>
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaRegCopy /> Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash /> Delete
        </button>
        <button
          className={`required-button  ${isRequired ? 'active' : ''}`}
          onClick={handleToggleRequired}
        >
          {isRequired ? <FaCheckCircle /> : <FaRegCircle />} {isRequired ? 'Required' : 'Optional'}
        </button>
      </div>
    </div>
  );
};

export default DynamicRatingGroup;
