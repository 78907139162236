import React, { useState, useCallback } from 'react';
import { FaCopy, FaTrash, FaStar, FaRegStar } from 'react-icons/fa';
// import './compo.css';

interface RadioGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    isRequired: boolean;
    choices: { value: string; text: string }[];
    correctAnswer: string[]; // Correct answer is an array of strings
  };
  index: number;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
}

const DynamicRanking: React.FC<RadioGroupProps> = ({
  question,
  index,
  updateQuestion,
  handleDuplicateQuestion,
  handleRemoveQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [editingName, _] = useState(question.name);
  const [editingTitle, setEditingTitle] = useState(question.title);  // New state for editing title

  // Sync local question state with the parent
  const syncWithParent = useCallback(() => {
    updateQuestion(index, { ...localQuestion, name: editingName, title: editingTitle });  // Include title in update
  }, [index, localQuestion, editingName, editingTitle, updateQuestion]);

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setEditingTitle(newTitle);
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
    syncWithParent();
  };

  // Handle toggling required
  const handleToggleRequired = () => {
    const updatedRequired = !localQuestion.isRequired;
    setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
    syncWithParent();
  };

  // Handle adding a choice
  const handleAddChoice = () => {
    const newChoice = { value: `feature${localQuestion.choices.length + 1}`, text: `Feature ${localQuestion.choices.length + 1}` };
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices, newChoice];
      return { ...prev, choices: updatedChoices };
    });
    syncWithParent();
  };

  // Handle removing a choice
  const handleRemoveChoice = (choiceIndex: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = prev.choices.filter((_, idx) => idx !== choiceIndex);
      const updatedCorrectAnswer = prev.correctAnswer.filter((answer) => answer !== prev.choices[choiceIndex].value);

      return { ...prev, choices: updatedChoices, correctAnswer: updatedCorrectAnswer };
    });
    syncWithParent();
  };

  // Handle editing a choice label
  const handleEditChoice = (choiceIndex: number, newLabel: string) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
      return { ...prev, choices: updatedChoices };
    });
    syncWithParent();
  };


  const handleCorrectAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedCorrectAnswers = inputValue.split(',').map((val) => val.trim());
  
    setLocalQuestion((prev) => ({ ...prev, correctAnswer: parsedCorrectAnswers }));
    syncWithParent();
  };
  


  return (
    <div className="question-container">
      <div className="question-heade">
        {/* Editable title */}
        {editingName}
        <input
          type="text"
          value={editingTitle}  // Bind the title input to editingTitle state
          onChange={handleTitleChange}  // Handle title change
          className="question-name-input"
          aria-label="Question Title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="radio-group">
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div key={choice.value} className="radio-item">
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
              aria-label={`Remove ${choice.text}`}
            >
              -
            </button>
     
            {editingChoiceIndex === choiceIndex ? (
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                onBlur={() => setEditingChoiceIndex(null)}
                autoFocus
                className="edit-label-input"
                aria-label={`Edit ${choice.text}`}
              />
            ) : (
              <span
                onClick={() => setEditingChoiceIndex(choiceIndex)}
                className="editable-text"
              >
                {choice.text}
              </span>
            )}
          </div>
        ))}
        <div className="radio-item">
          <button
            className="add-button"
            onClick={handleAddChoice}
            aria-label="Add Choice"
          >
            +
          </button>
        </div>
      </div>


      <div className="correct-answer-input">
        <label style={{fontSize:'14px'}}>Correct Answer(s): </label>
        <input
          type="text"
          value={Array.isArray(localQuestion.correctAnswer)
            ? localQuestion.correctAnswer.join(', ')  // If it's an array, display as a comma-separated string
            : localQuestion.correctAnswer}
          onChange={handleCorrectAnswerChange}
          placeholder="Enter correct answers, separated by commas"
        />
      </div>
      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaCopy style={{ color: 'orange' }} /> Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash style={{ color: 'orange' }} /> Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
          onClick={handleToggleRequired}
          aria-label={`Mark as ${localQuestion.isRequired ? 'Not Required' : 'Required'}`}
        >
          {localQuestion.isRequired ? (
            <FaStar style={{ color: 'orange', fontSize: '20px' }} />
          ) : (
            <FaRegStar style={{ color: 'orange' }} />
          )}
          {localQuestion.isRequired ? ' Required' : ' Required'}
        </button>
      </div>
    </div>
  );
};

export default DynamicRanking;
