import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import Input from "../ui/Input";

import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { doctorProfessionalProfileFormValidationSchema } from "../../validations/doctorValidation";
import { DoctorProfessionalProfileFormInputs } from "../../types/doctors";
import { useNavigate } from "react-router-dom";
import {
  erroMessageStyles,
  inputLabelStyles,
  selectStyles,
} from "../../utils/styles";
import { capitalizeFirstLetter, specializations } from "../../utils/common";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { updateDoctorProfessionalProfile } from "../../services/doctorService";

const DoctorProfessionalProfileForm = ({
  onClose,
  id = "",
  doctor = null,
}: {
  onClose: any;
  id?: string;
  doctor?: any;
}) => {
  // props & state values
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm<DoctorProfessionalProfileFormInputs>({
    resolver: yupResolver(doctorProfessionalProfileFormValidationSchema),
    defaultValues: {
      specializations: doctor
        ? doctor?.specializations
          ? doctor?.specializations
          : []
        : [],
      spokenLanguages: doctor
        ? doctor?.spokenLanguages
          ? doctor?.spokenLanguages
          : []
        : [],
      license: doctor ? doctor?.license || "" : "",
      licensingBoard: doctor ? doctor?.licensingBoard || "" : "",
      about: doctor ? doctor.about || "" : "",
      yearsOfExperience: doctor ? doctor.yearsOfExperience || null : null,
    },
  });

  const { showSnackbar, selectedDoctorInList, setSelectedDoctorInList } = useAppStore();
  const naviage = useNavigate();
  const spokenLanguagesValue = watch("spokenLanguages");

  const breadcrumbItems = [
    { label: "Doctors", href: "/doctors" },
    {
      label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || "")}`,
    },
    { label: "Professional Profile" },
  ];

  // callbacks & functions
  useEffect(() => {
    if (doctor) {
      console.log("doctor in form", doctor);
      const defaultValues = {
        specializations: doctor.specializations ? doctor.specializations : [],
        about: doctor.about || "",
        yearsOfExperience: doctor.yearsOfExperience || null,
        spokenLanguages: doctor.spokenLanguages || [],
        license: doctor?.license || "",
        licensingBoard: doctor?.licensingBoard || "",
      };
      reset(defaultValues);
      if (doctor?.specializations) {
        setValue("specializations", doctor.specializations || []); // Update the specializations value
      }
    }
  }, [doctor, reset]);

  const onSubmit: SubmitHandler<DoctorProfessionalProfileFormInputs> = async (
    values
  ) => {
    console.log("values on submit personal", values);
    const data = {
      doctorId: id,
      ...values,
    };

    try {
      if (doctor) {
        console.log("doctor edit", doctor);
        try {
          const response = await updateDoctorProfessionalProfile(data);

          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          })
          showSnackbar("Professional Profile Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        try {
          const response = await updateDoctorProfessionalProfile(data);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          })
          showSnackbar("Professional Profile Added Successfully", "success");
          naviage(`/doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId}/payment-settings`);
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
    } catch (error) {
      const message = doctor
        ? "Could not update professional profile, please try again"
        : "Could not add professional profile, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <CustomBreadcrumbs items={breadcrumbItems} />

          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              // startIcon={<AddIcon />}
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            maxHeight: "calc(100% - 3.25rem)",
            overflow: "auto",
          }}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <Input
                type="number"
                className="input-primary"
                label="Years Of Experience *"
                {...register("yearsOfExperience")}
                error={!!errors.yearsOfExperience}
                helperText={errors.yearsOfExperience?.message}
                inputProps={{ min: 0 }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="specializations"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      width: "100%",
                    }}
                  >
                    <Typography sx={inputLabelStyles}>
                      Specializations *
                    </Typography>
                    <Select
                      {...field}
                      fullWidth
                      multiple
                      sx={selectStyles.withGreyBackground}
                      margin="dense"
                      error={!!error}
                      defaultValue={
                        doctor
                          ? doctor?.specializations
                            ? doctor?.specializations
                            : []
                          : []
                      }
                    >
                      {specializations.map((specialization) => (
                        <MenuItem
                          key={specialization.id}
                          value={specialization.name}
                        >
                          {specialization.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <Typography sx={erroMessageStyles}>
                        {error.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="spokenLanguages"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  console.log("Current value:", field.value);
                  return (
                    <Input
                      type="text"
                      className="input-primary"
                      label="Spoken Languages *"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        console.log("inputValue", inputValue);

                        if (inputValue.includes(",")) {
                          const value = inputValue
                            .split(",")
                            .map((lang) => lang.trim())
                            .filter((lang) => lang); // Remove empty strings
                          setValue("spokenLanguages", value, {
                            shouldValidate: true,
                          });
                        } else {
                          const value = inputValue
                            .split(" ")
                            .map((lang) => lang.trim())
                            .filter((lang) => lang); // Remove empty strings
                          setValue("spokenLanguages", value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      defualtValue={
                        (spokenLanguagesValue || []).join(", ") || ""
                      }
                      // value={spokenLanguagesValue.join(", ") || ""}
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="License"
                {...register("license")}
                error={!!errors.license}
                helperText={errors.license?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Licensing Board"
                {...register("licensingBoard")}
                error={!!errors.licensingBoard}
                helperText={errors.licensingBoard?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                type="text"
                label="About"
                className="input-secondary-textarea"
                rows={3}
                multiline
                sx={{
                  "& textarea": {
                    minHeight: "30px",
                  },
                }}
                {...register("about")}
                error={!!errors.about}
                helperText={errors.about?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorProfessionalProfileForm;
