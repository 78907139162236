// import React, { useState } from "react";
// import PrescriptionIcon from "../../svg/PrescriptionIcon";
// import Button from "../../ui/Button";
// import Spinner from "../../ui/Spinner";
// import { downloadPrescription } from "../../../services/documentsService";
// import useAppStore from "../../../store/appStore";
// import { PrescriptionButtonProps } from "../../../types/appointments";

// const PrescriptionButton: React.FC<PrescriptionButtonProps> = ({
//   id,
//   prescription,
// }) => {
//   // props & state values
//   const { showSnackbar } = useAppStore();

//   const [loading, setLoading] = useState<boolean>(false);
//   const [isDownloading, setIsDownloading] = useState<boolean>(false);

//   // callbacks & functions
//   const downloadPrescriptionHandler = async (id: string) => {
//     setLoading(true);
//     showSnackbar("Prescription is downloading, please wait", "info");
//     try {
//       const response = await downloadPrescription(id);
//       const contentDisposition = response?.headers["content-disposition"];
//       const blobData = response?.data;
//       const fileName = contentDisposition;
//       const link = document.createElement("a");
//       link.href = window.URL.createObjectURL(blobData);
//       link.download = fileName;
//       link.click();
//       window.URL.revokeObjectURL(link.href);
//       showSnackbar("Prescription is downloaded", "success");
//       setLoading(false);
//       setIsDownloading(false);
//     } catch (error) {
//       showSnackbar(
//         "Could not download prescription, please try again",
//         "error"
//       );
//       setLoading(false);
//       setIsDownloading(false);
//     }
//   };

//   return (
//     <>
//       {prescription && prescription?.length > 0 ? (
//         <Button
//           startIcon={<PrescriptionIcon />}
//           color="secondary"
//           variant="outlined"
//           className="outlined-secondary-button"
//           onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
//             event.stopPropagation();
//             setIsDownloading(true);
//             downloadPrescriptionHandler(id);
//           }}
//           sx={{
//             height: "2.25rem",
//           }}
//           disabled={loading}
//         >
//           {loading && isDownloading ? <Spinner /> : "Prescription"}
//         </Button>
//       ) : null}
//     </>
//   );
// };

// export default PrescriptionButton;

import React, { useImperativeHandle, forwardRef } from "react";
import PrescriptionIcon from "../../svg/PrescriptionIcon";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import { downloadPrescription } from "../../../services/documentsService";
import useAppStore from "../../../store/appStore";
import { PrescriptionButtonProps } from "../../../types/appointments";

const PrescriptionButton = forwardRef<
  { handleButtonClick: () => void },
  PrescriptionButtonProps
>(({ id, prescription }, ref) => {
  const { showSnackbar } = useAppStore();
  const [loading, setLoading] = React.useState<boolean>(false);

  const downloadPrescriptionHandler = async () => {
    setLoading(true);
    showSnackbar("Prescription is downloading, please wait", "info");
    try {
      const response = await downloadPrescription(id);
      const contentDisposition = response?.headers["content-disposition"];
      const blobData = response?.data;
      const fileName = contentDisposition;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blobData);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
      showSnackbar("Prescription is downloaded", "success");
    } catch (error) {
      showSnackbar(
        "Could not download prescription, please try again",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  // Expose the click handler to the parent via the ref
  useImperativeHandle(ref, () => ({
    handleButtonClick: () => {
      downloadPrescriptionHandler();
    },
  }));

  return prescription && prescription?.length > 0 ? (
    <Button
      startIcon={<PrescriptionIcon />}
      color="secondary"
      variant="outlined"
      className="outlined-secondary-button"
      disabled={loading}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        downloadPrescriptionHandler();
      }}
      sx={{
        height: "2.25rem",
      }}
    >
      {loading ? <Spinner /> : "Prescription"}
    </Button>
  ) : null;
});

export default PrescriptionButton;
