import React from "react";
import { Box, } from "@mui/material";

interface ContentModalProps {
    open: boolean;
    children: React.ReactNode;
}

const ContentModal: React.FC<ContentModalProps> = ({
    open,
    children,
}) => {
    if (!open) return null; // Render nothing if modal is not open

    return (
        <Box
            sx={{
                position: "absolute",
                top: "64px", // Adjust based on the height of your header
                left: "240px", // Adjust based on the width of your sidebar
                right: "0",
                bottom: "0",
                backgroundColor: "#fff",
                boxShadow: 3,
                zIndex: 1200,
                overflow: "auto",
            }}
        >
            {/* Modal Content */}
            <Box sx={{ padding: 2 }}>{children}</Box>
        </Box>
    );
};

export default ContentModal;
