import { Box } from "@mui/material";
import Button from "../../ui/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import Spinner from "../../ui/Spinner";
import api from "../../../services/apiService";
import { extractAppointmentId } from "../../../utils/appointments";
import config from "../../../config";
import {
  failAppointmentBooking,
  fetchPayLater,
} from "../../../services/appointmentsService";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import { ONLINE } from "../../../utils/constants";
import { PatientAppointmentsProps, Tax } from "../../../types/common";
import { isClinicAdminOrAdmin } from "../../../utils/auth";

const PaymentFooter: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
  from = "",
}) => {
  // props & state values
  const [payLaterLoading, setPayLaterLoading] = useState<boolean>(false);

  const { showSnackbar, setAppointmentMeetId, setAppointmentConferenceView } =
    useAppStore();

  const {
    setAppointmentId,
    selectedDoctor,
    selectedMeetingType,
    availableSlotId,
    appointmentDate,
    cleanUpAppointmentState,
    selectedTime,
  } = useAppointmentStore();

  const navigate = useNavigate();

  const { user, userRole } = useAuthStore();

  const charges =
    selectedMeetingType === ONLINE
      ? selectedDoctor?.onlineConsultationFee || null
      : selectedDoctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = selectedDoctor?.taxes || [];

  const calculateTotalAmount = () => {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  // callbacks & functions
  const fetchfailAppointment = async (id: string) => {
    try {
      await failAppointmentBooking(id);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const processPayLater = async (paymentType: string, data: any) => {
    const params = {
      paymentType,
    };
    try {
      const response = await fetchPayLater(params, data);
      if (response?.data?.id) {
        handlePaymentSuccess(response?.data?.id);
      }
      setPayLaterLoading(false);
    } catch (error) {
      showSnackbar("Could not process pay later, please try again", "error");
      setPayLaterLoading(false);
    }
  };

  const handlePaymentSuccess = (id: string) => {
    if (from === "meet") {
      setAppointmentMeetId(id);
      setAppointmentConferenceView("Details");
    } else if (from === "appointment-cards") {
      navigate(`/appointment/${id}`);
    } else {
      if (patientId) {
        if (isClinicAdminOrAdmin(userRole)) {
          navigate(`/patients/${patientId}/appointments/${id}/details`);
        } else {
          navigate(
            `/appointments/patient/${patientId}/appointments/${id}/details`
          );
        }
      } else {
        navigate(`/appointment/${id}`);
      }
    }

    cleanUpAppointmentState();
  };

  const handlePayLater = async (query: string) => {
    let data;

    if (patientId) {
      data = {
        doctorId: selectedDoctor?.id as string,
        appointmentDate: appointmentDate,
        availableSlotId: availableSlotId,
        appointmentMode: selectedMeetingType,
        patientId: patientId,
      };
    } else {
      data = {
        doctorId: selectedDoctor?.id as string,
        appointmentDate: appointmentDate,
        availableSlotId: availableSlotId,
        appointmentMode: selectedMeetingType,
      };
    }

    processPayLater(query, data);
  };

  const handlePayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    let data;

    if (patientId) {
      data = {
        doctorId: selectedDoctor?.id as string,
        appointmentDate: appointmentDate,
        availableSlotId: availableSlotId,
        appointmentMode: selectedMeetingType,
        amount: totalAmount,
        patientId: patientId,
      };
    } else {
      data = {
        doctorId: selectedDoctor?.id as string,
        appointmentDate: appointmentDate,
        availableSlotId: availableSlotId,
        appointmentMode: selectedMeetingType,
        amount: totalAmount,
      };
    }

    try {
      const params = {
        paymentType: "PAYNOW",
      };
      const order = await api.post(`/api/payments/createOrder`, data, {
        params,
      });

      const idString = extractAppointmentId(order?.data?.receipt);

      setAppointmentId(idString);
      const { id, currency } = order.data;

      // Configure Razorpay checkout options
      const options = {
        key: config.RAZORPAY_KEY_ID!,
        totalAmount,
        currency: currency,
        name: config.APP_NAME,
        description: config.RAZORPAY_DESCRIPTION,
        order_id: id,
        handler: async (response: any) => {
          const data = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            appointmentId: idString?.trim(),
          };

          // Verify payment signature and update status
          try {
            const result = await api.post(
              `/api/payments/verifySignature`,
              data
            );
            if (result.data.status === "success") {
              showSnackbar("Payment successfull", "success");
              handlePaymentSuccess(idString);
            } else {
              showSnackbar("Payment not completed, please try again", "error");
            }
          } catch (error) {
            showSnackbar("Payment not completed, please try again", "error");
          }
        },
        prefill: {
          // name: patientName
          //   ? patientName
          //   : `${user?.firstName} ${user?.lastName}`,
          name: patientName ? patientName : `${user?.firstName}`,
          email: patientEmail ? patientEmail : user?.email,
          contact: patientContact ? patientContact : user?.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            // cancel appointment
            fetchfailAppointment(idString);
            showSnackbar("Payment was cancelled by the user", "info");
          },
        },
      };

      const rzp1 = new (window as any).Razorpay(options);
      rzp1.on("payment.failed", (response: any) => {
        alert("Payment failed. Reason: " + response.error.description);
      });
      rzp1.open();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    cleanUpAppointmentState();
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "1.188rem",
      }}
    >
      <Button
        variant="text"
        className="secondary-text-button"
        sx={{ marginRight: "1.25rem" }}
        onClick={handleCancel}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        className="success-gradient-button"
        sx={{
          textTransform: "none",
          marginRight: "1.25rem",
        }}
        onClick={handlePayment}
        disabled={totalAmount === null ? true : !selectedTime ? true : false}
      >
        Pay Rs. {totalAmount || ""}
      </Button>

      <Button
        sx={{ textTransform: "none", marginRight: "1.25rem" }}
        className="warning-button"
        variant="contained"
        disabled={
          totalAmount === null
            ? true
            : !selectedTime
              ? true
              : payLaterLoading
                ? true
                : false
        }
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          handlePayLater("PAYLATER");
        }}
      >
        {payLaterLoading ? <Spinner /> : "Pay Later"}
      </Button>

      {patientId && (
        <Button
          sx={{ textTransform: "none", marginRight: "1.25rem" }}
          className="primary-button"
          variant="contained"
          disabled={totalAmount === null ? true : !selectedTime ? true : false}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            handlePayLater("OFFLINE");
          }}
        >
          Pay Offline
        </Button>
      )}
    </Box>
  );
};

export default PaymentFooter;
