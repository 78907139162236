import { useLocation } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  // Box,
  //  Drawer,
  useMediaQuery,
} from "@mui/material";
import useAuthStore from "../../store/authStore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MedicationIcon from "@mui/icons-material/Medication";
import PeopleIcon from "@mui/icons-material/People";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ShareIcon from "@mui/icons-material/Share";
import useAppStore from "../../store/appStore";
import CustomDrawer from "./CustomDrawer";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import {
  ADMIN,
  CLINIC_ADMIN,
  DOCTOR,
  NETWORK,
  PATIENT,
} from "../../utils/constants";
import NewSidebarContent from "./NewSidebarContent";
import SubmenuSidebarContent from "./SubmenuSidebarContent";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

const SidebarNav = () => {
  // props & state values
  const {
    isMenuCollapsed,
    drawerOpen,
    selectedPatientInList,
    selectedDoctorInList,
    showDoctorSubmenus,
    showFormSubmenus,
    selectedFormInList,
    // showDoctorMeetSubmenus,
    // appointmentIdForMeet
  } = useAppStore();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:50rem)");

  const { userRole } = useAuthStore();
  const userId = localStorage.getItem("formid");
  const menuItems = [
    {
      route: "dashboard",
      text: "Dashboard",
      icon: <HomeIcon />,
      selected: location.pathname.includes("dashboard"),
      roles: [PATIENT, DOCTOR, ADMIN, NETWORK, CLINIC_ADMIN],
    },
    {
      route: "appointments",
      text: "Appointments",
      icon: <CalendarTodayIcon />,
      selected:
        (location.pathname.includes("appointments") &&
          !location.pathname.includes("patients")) ||
        (location.pathname.includes("appointment") &&
          !location.pathname.includes("patients")) ||
        location.pathname.includes("meet") ||
        location.pathname.includes("specialties") ||
        Boolean(
          location.pathname.match(/^\/doctors\/[^/]+\/details$/) &&
          !isClinicAdminOrAdmin(userRole)
        ),
      roles: [PATIENT, DOCTOR, ADMIN, CLINIC_ADMIN],
      // submenus: showDoctorMeetSubmenus && appointmentIdForMeet
      //   ? [
      //     {
      //       route: `meet/${appointmentIdForMeet}/profile`,
      //       text: "Profile",
      //       icon: <PersonIcon />,
      //       selected: !!location.pathname.match(
      //         /^\/meet\/[a-zA-Z0-9]+\/profile$/
      //       ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/documents`,
      //       text: "Documents",
      //       icon: <FolderIcon />,
      //       selected:
      //         !!location.pathname.match(
      //           /^\/meet\/[a-zA-Z0-9]+\/documents$/
      //         ) ||
      //         !!location.pathname.match(
      //           /^\/meet\/[a-zA-Z0-9]+\/documents\/view$/
      //         ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/appointments`,
      //       text: "Appointments",
      //       icon: <CalendarTodayIcon />,
      //       selected:
      //         !!location.pathname.match(
      //           /^\/meet\/[a-zA-Z0-9]+\/appointments$/
      //         ) ||
      //         !!location.pathname.match(
      //           /^\/meet\/[a-zA-Z0-9]+\/appointments(\/book|\/[a-zA-Z0-9]+\/details)?$/
      //         ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/forms`,
      //       text: "Forms",
      //       icon: <DynamicFormIcon />,
      //       selected: !!location.pathname.match(
      //         /^\/meet\/[a-zA-Z0-9]+\/forms$/
      //       ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/abha-details`,
      //       text: "ABHA Details",
      //       icon: <PermContactCalendarIcon />,
      //       selected: !!location.pathname.match(
      //         /^\/meet\/[a-zA-Z0-9]+\/abha-details$/
      //       ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/doctor-notes`,
      //       text: "Doctor Notes",
      //       icon: <StackedBarChartIcon />,
      //       selected: !!location.pathname.match(
      //         /^\/meet\/[a-zA-Z0-9]+\/doctor-notes$/
      //       ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //     {
      //       route: `meet/${appointmentIdForMeet}/medication`,
      //       text: "Medication",
      //       icon: <StackedBarChartIcon />,
      //       selected: !!location.pathname.match(
      //         /^\/meet\/[a-zA-Z0-9]+\/medication$/
      //       ),
      //       roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      //     },
      //   ]
      //   : [],
    },
    // {
    //   route: "settings",
    //   text: "Settings",
    //   icon: <CalendarTodayIcon />,
    //   selected: location.pathname.includes("settings"),
    //   roles: ["PATIENT", "DOCTOR"],
    // },
    {
      route: "documents",
      text: "Documents",
      icon: <FolderIcon />,
      selected:
        location.pathname.includes("documents") &&
        !location.pathname.includes("patients"),
      roles: [PATIENT],
    },
    {
      route: "referrals",
      text: "Referrals",
      icon: <ShareIcon />,
      selected: location.pathname.includes("referrals"),
      roles: [NETWORK, ADMIN, CLINIC_ADMIN],
    },
    {
      route: "patients",
      text: "Patients",
      icon: <PeopleIcon />,
      selected: location.pathname.includes("patients"),
      roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      submenus: selectedPatientInList
        ? [
          {
            route: `patients/${selectedPatientInList.id}/profile`,
            text: "Profile",
            icon: <PersonIcon />,
            selected: !!location.pathname.match(
              /^\/patients\/[a-zA-Z0-9]+\/profile$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
          {
            route: `patients/${selectedPatientInList.id}/documents`,
            text: "Documents",
            icon: <FolderIcon />,
            selected:
              !!location.pathname.match(
                /^\/patients\/[a-zA-Z0-9]+\/documents$/
              ) ||
              !!location.pathname.match(
                /^\/patients\/[a-zA-Z0-9]+\/documents\/view$/
              ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
          {
            route: `patients/${selectedPatientInList.id}/appointments`,
            text: "Appointments",
            icon: <CalendarTodayIcon />,
            selected:
              !!location.pathname.match(
                /^\/patients\/[a-zA-Z0-9]+\/appointments$/
              ) ||
              !!location.pathname.match(
                /^\/patients\/[a-zA-Z0-9]+\/appointments(\/book|\/[a-zA-Z0-9]+\/details)?$/
              ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
          {
            route: `patients/${selectedPatientInList.id}/forms`,
            text: "Forms",
            icon: <DynamicFormIcon />,
            selected: !!location.pathname.match(
              /^\/patients\/[a-zA-Z0-9]+\/forms$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
          {
            route: `patients/${selectedPatientInList.id}/abha-details`,
            text: "ABHA Details",
            icon: <PermContactCalendarIcon />,
            selected: !!location.pathname.match(
              /^\/patients\/[a-zA-Z0-9]+\/abha-details$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
          ...(isClinicAdminOrAdmin(userRole)
            ? [
              {
                route: `patients/${selectedPatientInList.id}/audit-logs`,
                text: "Audit Logs",
                icon: <StackedBarChartIcon />,
                selected: !!location.pathname.match(
                  /^\/patients\/[a-zA-Z0-9]+\/audit-logs$/
                ),
                roles: [ADMIN, CLINIC_ADMIN],
              },
            ]
            : []),
        ]
        : [],
    },
    {
      route: "clinics",
      text: "Clinics",
      icon: <LocalHospitalIcon />,
      selected: location.pathname.includes("clinics"),
      roles: [ADMIN, CLINIC_ADMIN],
    },
    {
      route: "doctors",
      text: "Doctors",
      icon: <MedicationIcon />,
      selected: location.pathname.includes("doctors"),
      roles: [ADMIN, CLINIC_ADMIN],
      submenus: showDoctorSubmenus
        ? [
          {
            route: `doctors/personal-profile`,
            text: "Personal Profile",
            icon: <PersonIcon />,
            selected: location.pathname.includes("personal-profile"),
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/professional-profile`,
            text: "Professional Profile",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/professional-profile$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/payment-settings`,
            text: "Payment Settings",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/payment-settings$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/consultation-settings`,
            text: "Consultation Settings",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/consultation-settings$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/work-experience`,
            text: "Work Experience",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/work-experience$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/academic-qualifications`,
            text: "Academic Qualifications",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/academic-qualifications$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId || ""}/leave-settings`,
            text: "Leave Settings",
            icon: <FolderIcon />,
            selected: !!location.pathname.match(
              /^\/doctors\/[a-zA-Z0-9]+\/leave-settings$/
            ),
            disabled: !selectedDoctorInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
        ]
        : [],
    },
    {
      route: "forms",
      text: "Forms",
      icon: <MedicationIcon />,
      selected: location.pathname.includes("forms"),
      roles: [ADMIN, CLINIC_ADMIN],
      submenus: showFormSubmenus
        ? [
          {
            route: `forms/new`,
            text: "Form settings",
            icon: <PersonIcon />,
            selected: location.pathname.includes("new"),
            roles: [ADMIN, CLINIC_ADMIN],
          },
          {
            route: `forms/${userId}/create`,
            text: "Form Creator",
            icon: <PersonIcon />,
            selected: location.pathname.includes("create"),
            disabled: !selectedFormInList,
            roles: [ADMIN, CLINIC_ADMIN],
          },
         
          // {
          //   route: `forms/${userId}/settings`,
          //   text: "Form settings",
          //   icon: <PersonIcon />,
          //   selected: location.pathname.includes("settings"),
          //   disabled: !selectedFormInList,
          //   roles: [ADMIN, CLINIC_ADMIN],
          // },
          // {
          //   route: `formscreator/${selectedDoctorInList?.id || ""}/availability`,
          //   text: "Radio Button Group",
          //   icon: <FolderIcon />,
          //   selected: !!location.pathname.match(
          //     /^\/formscreator\/[a-zA-Z0-9]+\/availability$/
          //   ),
          //   disabled: !selectedDoctorInList,
          //   roles: [ADMIN, CLINIC_ADMIN],
          // },

        ]
        : [],
    },
  ];

  const filteredMenuItems = menuItems.filter((item) =>
    item.roles.includes(userRole as string)
  );

  // Find the selected menu item
  const selectedMenuItem = filteredMenuItems.find((item) => item.selected);

  // Check if the selected menu has submenus
  const hasSubmenus =
    selectedMenuItem &&
    selectedMenuItem.submenus &&
    Array.isArray(selectedMenuItem.submenus) &&
    selectedMenuItem.submenus.length > 0;



  // return (
  //   <>
  //     {isMobile ? (
  //       <CustomDrawer open={drawerOpen}>
  //         <Box sx={{ display: "flex", height: "100%" }}>
  //           <NewSidebarContent
  //             menuItems={filteredMenuItems}
  //             isMenuCollapsed={isMenuCollapsed}
  //             showIconsOnly={hasSubmenus}
  //             isMobile
  //           />
  //           {hasSubmenus && (
  //             <SubmenuSidebarContent
  //               menuItems={selectedMenuItem.submenus}
  //               isMenuCollapsed={isMenuCollapsed}
  //               showIconsOnly={isMenuCollapsed}
  //             />
  //           )}
  //         </Box>
  //       </CustomDrawer>
  //     ) : (
  //       <Box sx={{ display: "flex" }}>
  //         <NewSidebarContent
  //           menuItems={filteredMenuItems}
  //           isMenuCollapsed={isMenuCollapsed}
  //           showIconsOnly={isMenuCollapsed}
  //         />
  //         {hasSubmenus && (
  //           <SubmenuSidebarContent
  //             menuItems={selectedMenuItem.submenus}
  //             isMenuCollapsed={isMenuCollapsed}
  //             showIconsOnly={isMenuCollapsed}
  //           />
  //         )}
  //       </Box>
  //     )}
  //   </>
  // );
  return (
    <>
      {isMobile ? (
        <CustomDrawer open={drawerOpen}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <NewSidebarContent
              menuItems={filteredMenuItems}
              isMenuCollapsed={isMenuCollapsed}
              showIconsOnly={hasSubmenus}
              isMobile
            />
            {hasSubmenus && (
              <SubmenuSidebarContent
                menuItems={selectedMenuItem.submenus}
                isMenuCollapsed={isMenuCollapsed}
                showIconsOnly={isMenuCollapsed}
              />
            )}
            {/* Display Radio Buttons and Checkboxes if "Form Creator" is not disabled */}
            {selectedMenuItem?.route === 'formscreator1' && (
              <Box sx={{ padding: 2 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Form Settings</FormLabel>
                  <FormGroup>
                    {/* Example Radio Button */}
                    <FormControlLabel
                      control={<Radio />}
                      label="Radio Button 1"
                    />
                    {/* Example Checkbox */}
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Checkbox 1"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            )}
          </Box>
        </CustomDrawer>
      ) : (
        <Box sx={{ display: "flex" }}>
          <NewSidebarContent
            menuItems={filteredMenuItems}
            isMenuCollapsed={isMenuCollapsed}
            showIconsOnly={isMenuCollapsed}
          />
          {hasSubmenus && (
            <SubmenuSidebarContent
              menuItems={selectedMenuItem.submenus}
              isMenuCollapsed={isMenuCollapsed}
              showIconsOnly={isMenuCollapsed}
            />
          )}
          {/* Display Radio Buttons and Checkboxes if "Form Creator" is not disabled */}
          {selectedMenuItem?.route === 'formscreator1' && (
            <Box sx={{ padding: 2 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Form Settings</FormLabel>
                <FormGroup>
                  {/* Example Radio Button */}
                  <FormControlLabel
                    control={<Radio />}
                    label="Radio Button 1"
                  />
                  {/* Example Checkbox */}
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Checkbox 1"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          )}
        </Box>
      )}
    </>
  );

};

export default SidebarNav;
