

import React, { useState, useEffect } from 'react';
import { FaCopy, FaTrash, FaStar, FaRegStar } from 'react-icons/fa';
// import './component.css';

interface DropdownGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; text: string }[];
    isRequired: boolean;
    correctAnswer: string[] | string;
  };
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
}

const DynamicSelect: React.FC<DropdownGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);

  // Debounced synchronization with the parent component
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 300); // Adjust debounce time as needed
    return () => clearTimeout(timeout); // Cleanup previous timeout
  }, [localQuestion, index, updateQuestion]);

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
  };

  // Handle toggling the required field
  const handleToggleRequired = () => {
    setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
  };

  // Handle adding a new choice
  const handleAddChoice = () => {
    const newChoice = {
      value: `option${localQuestion.choices.length + 1}`,
      text: `Option ${localQuestion.choices.length + 1}`,
    };
    setLocalQuestion((prev) => ({
      ...prev,
      choices: [...prev.choices, newChoice],
    }));
  };

  // Handle removing a choice
  const handleRemoveChoice = (choiceIndex: number) => {
    setLocalQuestion((prev) => ({
      ...prev,
      choices: prev.choices.filter((_, idx) => idx !== choiceIndex),
    }));
  };

  // Handle editing a choice
  const handleEditChoice = (choiceIndex: number, newText: string) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex].text = newText;
      return { ...prev, choices: updatedChoices };
    });
  };

  // Handle dropdown selection change
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setLocalQuestion((prev) => ({ ...prev, correctAnswer: selectedValue }));
  };

  // Handle correct answer input field
  const handleCorrectAnswerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const values = inputValue.split(',').map((val) => val.trim());
    setLocalQuestion((prev) => ({
      ...prev,
      correctAnswer: values.length > 1 ? values : values[0],
    }));
  };

  return (
    <div className="question-container">
      {/* Title Input */}
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Dropdown Options */}
      <div className="dropdown-group">
        <select
          name={question.name}
          className="dropdown-select"
          value={
            Array.isArray(localQuestion.correctAnswer)
              ? localQuestion.correctAnswer[0]
              : localQuestion.correctAnswer
          }
          onChange={handleDropdownChange}
        >
          {localQuestion.choices.map((choice, choiceIndex) => (
            <option key={choiceIndex} value={choice.value}>
              {choice.text}
            </option>
          ))}
        </select>

        {/* Editable Choices */}
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div className="editable-choice-container" key={choiceIndex}>
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
            >
              -
            </button>
            <div className="editable-choice">
              {editingChoiceIndex === choiceIndex ? (
                <input
                  type="text"
                  value={choice.text}
                  onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                  onBlur={() => setEditingChoiceIndex(null)}
                  autoFocus
                  className="edit-label-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => setEditingChoiceIndex(choiceIndex)}
                >
                  {choice.text}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Add Choice Button */}
      <div className="dropdown-item">
        <button className="add-button" onClick={handleAddChoice}>
          +
        </button>
      </div>

      {/* Correct Answer Input */}
      <div className="correct-answer-input">
        <label style={{ fontSize: '14px' }}>Correct Answer(s): </label>
        <input
          type="text"
          value={
            Array.isArray(localQuestion.correctAnswer)
              ? localQuestion.correctAnswer.join(', ')
              : localQuestion.correctAnswer
          }
          onChange={handleCorrectAnswerInput}
          placeholder="Enter correct answers, separated by commas"
        />
      </div>

      {/* Control Buttons */}
      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaCopy style={{ color: 'orange' }} /> Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash style={{ color: 'orange' }} /> Delete
        </button>

        <button
          className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
          onClick={handleToggleRequired}
          aria-pressed={localQuestion.isRequired}
        >
          {localQuestion.isRequired ? (
            <FaStar style={{ color: 'orange', fontSize: '20px' }} />
          ) : (
            <FaRegStar style={{ color: 'orange' }} />
          )}
          {localQuestion.isRequired ? ' Required' : ' Required'}
        </button>
      </div>
    </div>
  );
};

export default DynamicSelect;

