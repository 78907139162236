import React, { useState } from 'react';
import { FaCopy, FaTrash } from 'react-icons/fa';
// import './component.css';

interface DropdownGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; text: string }[];
    isRequired: boolean;
    correctAnswer: string[]; // Correct answers as an array of strings
  };
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void; // Added prop for syncing question updates
}

const DynamicSelectMulti: React.FC<DropdownGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);


  // Synchronize on state update
  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  // Handle adding a new choice
  const handleAddChoice = () => {
    const newChoice = { value: `option${localQuestion.choices.length + 1}`, text: `Option ${localQuestion.choices.length + 1}` };
    updateLocalQuestion({
      ...localQuestion,
      choices: [...localQuestion.choices, newChoice],
    });
  };

  // Handle removing a choice
  const handleRemoveChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter((_, idx) => idx !== choiceIndex);
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  // Handle editing a choice
  const handleEditChoice = (choiceIndex: number, newText: string) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex].text = newText;
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };


  return (
    <div className="question-container">
      {/* Title Input */}
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Multi-select Dropdown */}
      <div className="dropdown-group">
        <select
          name={question.name}
          className="dropdown-select"
          multiple
          value={localQuestion.correctAnswer} // Correctly bind selected answers
          onChange={(e) => {
            // Collect selected values and merge with previous ones
            const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);

            // Combine previous and new values, ensuring uniqueness
            const updatedAnswers = Array.from(new Set([...localQuestion.correctAnswer, ...selectedValues]));

            updateLocalQuestion({ ...localQuestion, correctAnswer: updatedAnswers });
          }}
        >
          {localQuestion.choices.map((choice, choiceIndex) => (
            <option key={choiceIndex} value={choice.value}>
              {choice.text}
            </option>
          ))}
        </select>
      </div>





      {/* Editable Choices */}
      {localQuestion.choices.map((choice, choiceIndex) => (
        <div className="editable-choice-container" key={choiceIndex}>
          <button className="remove-button" onClick={() => handleRemoveChoice(choiceIndex)}>
            -
          </button>
          <div className="editable-choice">
            {editingChoiceIndex === choiceIndex ? (
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                onBlur={() => setEditingChoiceIndex(null)}
                autoFocus
                className="edit-label-input"
              />
            ) : (
              <span
                className="editable-text"
                onClick={() => setEditingChoiceIndex(choiceIndex)}
              >
                {choice.text}
              </span>
            )}
          </div>
        </div>
      ))}

      {/* Add Choice Button */}
      <div className="dropdown-item">
        <button className="add-button" onClick={handleAddChoice}>
          +
        </button>
      </div>

      <div className="correct-answer-input">
        <label style={{ fontSize: '14px' }}>Selected Answer(s):</label>
        <div className="selected-answers">
          {localQuestion.correctAnswer.map((answer, idx) => {
            const choice = localQuestion.choices.find((c) => c.value === answer); // Find the choice by value
            return (
              <div key={idx} className="selected-answer-item">
                <span>{choice?.text || answer}</span>
                <button
                  className="remove-button"
                  onClick={() => {
                    // Remove the selected answer
                    const updatedAnswers = localQuestion.correctAnswer.filter((ans) => ans !== answer);
                    updateLocalQuestion({ ...localQuestion, correctAnswer: updatedAnswers });
                  }}
                >
                  x
                </button>
              </div>
            );
          })}
        </div>
      </div>



      {/* Control Buttons */}
      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaCopy style={{ color: 'orange' }} /> Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash style={{ color: 'orange' }} /> Delete
        </button>

        
      </div>
    </div>
  );
};

export default DynamicSelectMulti;
