import React, { useEffect, useState } from "react";
import { Document, DocumentsTableViewProps } from "../../../types/documents";
import { Box, IconButton, Typography } from "@mui/material";
import { capitalizeFirstLetter, getFormattedDate } from "../../../utils/common";
import { TableWrapper } from "../../shared/TableWrapper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ImageIcon from "@mui/icons-material/Image";
import useDocumentStore from "../../../store/documentsStore";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../../store/appStore";
import { isClinicAdminOrAdmin } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";
import DeleteIcon from "../../svg/DeleteIcon";
import ConfirmModal from "../../shared/ConfirmModal";
import DownloadSvgIcon from "../../svg/DownloadIcon";
import Tooltip from "../../ui/Tooltip";

const DocumentsTableView: React.FC<DocumentsTableViewProps> = ({
  // handleEdit,
  // handleDelete,
  documents,
  patientId,
  isFromMeet = false,
  from = "",
}) => {
  // props & state values
  const {
    setSelectedDocumentId,
    deleteDocument,
    loadingDeleteDocument,
    errorDeleteDocument,
    documentDeleted,
    cleanUpDeleteDocumentState,
    fetchDocuments,
  } = useDocumentStore();

  const { setDocumentsConferenceView, showSnackbar, documentsFilter } =
    useAppStore();
  const { userRole } = useAuthStore();
  const navigate = useNavigate();

  const [selectedRowId, setSelectedRowId] = useState<any>(null);

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  // callbacks & functions
  useEffect(() => {
    if (loadingDeleteDocument) {
      showSnackbar("Deleting document, please wait", "info");
    } else if (errorDeleteDocument) {
      showSnackbar("Could not delete document, please try again", "error");
      cleanUpDeleteDocumentState();
    } else if (documentDeleted) {
      showSnackbar("Document deleted", "success");
      if (patientId) {
        fetchDocuments(
          patientId,
          documentsFilter.customStartDate,
          documentsFilter.customEndDate,
          documentsFilter.searchValue || ""
        );
      } else {
        fetchDocuments(
          "",
          documentsFilter.customStartDate,
          documentsFilter.customEndDate,
          documentsFilter.searchValue || ""
        );
      }
      cleanUpDeleteDocumentState();
    }
  }, [
    loadingDeleteDocument,
    errorDeleteDocument,
    documentDeleted,
    documentsFilter.customStartDate,
    documentsFilter.customEndDate,
  ]);

  const FileTypeIcon: React.FC<{ fileType: string }> = ({ fileType }) => {
    const iconStyles = {
      width: "2rem",
      height: "2.5rem",
      fill: "var(--neuro-svg-error)",
      color: "var(--neuro-svg-error)",
      display: "block",
    };

    const fileTypeIconMap: { [key: string]: JSX.Element } = {
      PDF: <PictureAsPdfIcon sx={iconStyles} />,
      DICOM: <NoteAddIcon sx={iconStyles} />,
      AUDIO: <AudioFileIcon sx={iconStyles} />,
      ZIP: <FolderZipIcon sx={iconStyles} />,
    };

    return fileTypeIconMap[fileType] || <ImageIcon sx={iconStyles} />;
  };

  const handleReportDownload = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    filePath: any
  ) => {
    event.stopPropagation();

    if (filePath) {
      try {
        // Fetch the file to ensure it exists and create a Blob URL if needed
        const response = await fetch(filePath);

        if (!response.ok) {
          throw new Error("File not found");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop() || "download"); // Set the filename
        document.body.appendChild(link);

        link.click();

        // Clean up by revoking the Blob URL and removing the element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Download error:", error);
        showSnackbar("Could not download the file, please try again", "info");
      }
    } else {
      showSnackbar("Could not download the file, please try again", "info");
    }
  };

  const handleDeleteDocumet = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: any
  ) => {
    event.stopPropagation();
    setSelectedRowId(id);
    setOpenConfirmModal(true);
  };

  const handleConfirm = (id: string) => {
    if (patientId) {
      deleteDocument(id, patientId);
    } else {
      deleteDocument(id);
    }
    setOpenConfirmModal(false);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  // table columns
  const columns = [
    {
      id: "name",
      label: "Document Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.name || "";
        const bName = b?.name || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: Document) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <FileTypeIcon fileType={row?.fileType || ""} />
          <Typography>{`${capitalizeFirstLetter(row?.name || "") || ""}`}</Typography>
        </Box>
      ),
    },
    {
      id: "reportDate",
      label: "Date",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aAddress = a?.reportDate || "";
        const bAddress = b?.reportDate || "";

        // Move empty or null values to the end
        if (aAddress === "") return 1;
        if (bAddress === "") return -1;

        // Normal locale comparison for non-empty values
        return aAddress.localeCompare(bAddress);
      },
      render: (value: any) => getFormattedDate(value || "") || "-",
    },
    {
      id: "createdAt",
      label: "Upload At",
      sortable: true,
      render: (value: string) => getFormattedDate(value || "") || "-",
    },
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: any) => (
        <Box
          sx={{
            width: "max-content",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Tooltip title="Download" placement="top">
            <IconButton
              onClick={(event) => handleReportDownload(event, row?.filePath)}
            >
              <DownloadSvgIcon />
            </IconButton>
          </Tooltip>

          {row?.status === "DELETED" ? (
            <IconButton
              disabled={true}
              onClick={(event) => handleDeleteDocumet(event, row?.id)}
              sx={{
                "&.Mui-disabled > svg": {
                  opacity: 0.5,
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <Tooltip title="Delete Document" placement="top">
              <IconButton
                onClick={(event) => handleDeleteDocumet(event, row?.id)}
                sx={{
                  "&.Mui-disabled > svg": {
                    opacity: 0.5,
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const TableRowClick = (row: any) => {
    setSelectedDocumentId(row?.id);
    if (from === "list" && !patientId) {
      navigate("/documents/view");
    }
    if (patientId) {
      if (isFromMeet) {
        setDocumentsConferenceView("Details");
      } else {
        if (isClinicAdminOrAdmin(userRole)) {
          navigate(`/patients/${patientId}/documents/view`);
        } else {
          navigate(`/appointments/patient/${patientId}/documents/view`);
        }
      }
    }
    // if (from === "conference" && onClick) {
    //   onClick();
    // }
  };

  return (
    <>
      <TableWrapper
        columns={columns}
        rows={documents}
        stickyHeader={true}
        maxHeight="calc(100% - 6.25rem)"
        onRowClick={TableRowClick}
        rowSx={{
          cursor: "pointer",
        }}
      />
      <ConfirmModal
        open={openConfirmModal}
        description="Are you sure, you want to delete this document"
        handleConfirm={() => handleConfirm(selectedRowId || "")}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default DocumentsTableView;
