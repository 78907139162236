// third-party imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

//project imports
import Button from "../../ui/Button";
import Conference from "./Conference";
import DoctorsNote from "./DoctorsNote";
import GeneralDetails from "./GeneralDetails";
import Medication from "./Medication";
import PatientHeader from "./PatientHeader";
import { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { isClinicAdminOrAdmin, isDoctor, isPatient } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import useAppStore from "../../../store/appStore";
import {
  getConsultationSummary,
  postConsultationSummary,
} from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
// import { uploadRecording } from "../../../services/documentsService";
import ConfirmModal from "../../shared/ConfirmModal";
import { ONLINE } from "../../../utils/constants";

const Meet = ({
  id,
  closeButtonHandler,
  hideConference = false,
  hideGeneralDetails = false,
  hidePatientHeader = false,
  showCloseButton = false,
  // showLayoutHeader = false,
  height = "100%",
}: {
  id?: string;
  hideConference?: boolean;
  hideGeneralDetails?: boolean;
  hidePatientHeader?: boolean;
  showCloseButton?: boolean;
  closeButtonHandler?: any;
  showLayoutHeader?: boolean;
  height?: string | number;
}) => {
  // props & state values
  const { appointmentId } = useParams();
  const location = useLocation();
  const { appointmentMode } = location.state || {};
  const navigate = useNavigate();

  const { medicines } = useAppointmentStore();

  const { isMenuCollapsed, setDrawerOpen, setIsMenuCollapsed, showSnackbar, setShowDoctorMeetSubmenus, setAppointmentIdForMeet } =
    useAppStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [createSummaryLoading, setCreateSummaryLoading] =
    useState<boolean>(false);

  const isBelow800 = useMediaQuery("(max-width:50rem)");

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  const [summaryDetails, setSummaryDetails] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  console.log("summaryDetails", summaryDetails);

  const { userRole } = useAuthStore();

  // callbacks & functions
  useEffect(() => {
    if (!isPatient(userRole)) {
      setShowDoctorMeetSubmenus(true);
    }
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  // useEffect(() => {
  //   if (!showLayoutHeader) {
  //     setIsMenuCollapsed(true);
  //     setHelpModalOpen(false);
  //     setShowSidebar(true);
  //   }

  //   return () => {
  //     setShowHeader(true);
  //     setShowSidebar(true);
  //     setHelpModalOpen(true);
  //   };
  // }, [showLayoutHeader]);

  const fetchConsultationSummary = async (id: string) => {
    try {
      const response = await getConsultationSummary(id);
      setSummaryDetails(response?.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const createSummary = async (data: any, saveType?: string) => {
    setCreateSummaryLoading(true);
    showSnackbar("Updating medications, please wait", "info");
    let params;
    if (saveType) {
      params = {
        saveType,
      };
    } else {
      params = undefined;
    }

    try {
      const response = await postConsultationSummary(data, params);
      if (response?.data) {
        showSnackbar("Medications updated successfully", "success");
        // await uploadRecording(
        //   summaryDetails?.patientGeneralDetails?.id || "",
        //   appointmentId || ""
        // );
      }
      setCreateSummaryLoading(false);
    } catch (error) {
      showSnackbar("Could not update medications", "error");
      setCreateSummaryLoading(false);
    }
  };

  const handleConfirm = async () => {
    const navigation = () => {
      if (isClinicAdminOrAdmin(userRole)) {
        navigate(
          `/patients/${summaryDetails?.patientGeneralDetails?.id || ""}/appointments`
        );
      } else {
        navigate("/appointments");
      }
    };

    try {
      const data = {
        appointmentId: appointmentId || id,
      };
      await postConsultationSummary(data);
      navigation();
    } catch (error) {
      navigation();
      console.error("error", error);
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    if (appointmentId || id) {
      setAppointmentIdForMeet(appointmentId || id);
      fetchConsultationSummary(appointmentId || id || "");
    }
  }, [appointmentId, id]);

  const handleCreateSummary = () => {
    const medicineData =
      medicines && medicines.length > 0
        ? medicines
          .filter((medicine) => {
            // Check that all required fields have values
            return (
              medicine.medication.trim() !== "" &&
              medicine.medicationType.trim() !== "" &&
              String(medicine.dosageAmount).trim() !== "" &&
              medicine.dosageUnit.trim() !== "" &&
              String(medicine.durationAmount).trim() !== "" &&
              medicine.durationUnit.trim() !== "" &&
              medicine.frequency.trim() !== ""
            );
          })
          .map((medicine) => ({
            ...medicine,
            dosageAmount: Number(medicine.dosageAmount),
            durationAmount: Number(medicine.durationAmount),
          }))
        : [];

    const data = {
      appointmentId: appointmentId || id,
      medications: medicineData,
    };

    if (appointmentId || id) {
      createSummary(data, "MEDICATIONS");
    }
  };

  return (
    <Box
      sx={{
        // paddingBlock: "28px",
        // paddingInline: "20px",
        width: "100%",
        height: height, // Ensure it takes full viewport height
        overflow: "hidden", // Prevent any potential overflow
      }}
    >
      <Grid container sx={{ height: height, overflow: "hidden" }}>
        {appointmentMode && appointmentMode === ONLINE && !hideConference && (
          <Grid
            item
            xs={12}
            // md={6}
            md={isDoctor(userRole) ? 6 : 12}
            sx={{ height: height, overflow: "hidden" }} // Ensure full height
          >
            <Conference appointmentId={appointmentId || id || ""} />
          </Grid>
        )}

        {isDoctor(userRole) && (
          <Grid
            item
            xs={12}
            md={
              appointmentMode && appointmentMode === ONLINE
                ? hideConference
                  ? 12
                  : 6
                : 12
            }
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "var(--neuro-white-text)",
              borderLeft: "1px solid var(--neuro-black-text)",
            }}
          >
            {loading && <LoadingScreen />}

            {!loading && !summaryDetails && (
              <ContentCenter>
                <Typography className="no-data">Details not found</Typography>
              </ContentCenter>
            )}

            {!loading && summaryDetails && (
              <>
                {!hidePatientHeader && (
                  <PatientHeader
                    name={
                      capitalizeWordsPreservingSpaces(
                        summaryDetails?.patientGeneralDetails?.name || ""
                      ) || ""
                    }
                  />
                )}

                <Box
                  sx={{
                    paddingInline: "0.938rem",
                    paddingTop: "1.125rem",
                    paddingBottom: "6.25rem",
                    height: hidePatientHeader ? "100%" : "calc(100% - 5rem)",
                    overflowY: "auto",
                  }}
                >
                  {!hideGeneralDetails && (
                    <GeneralDetails
                      patientDetails={summaryDetails?.patientGeneralDetails}
                    />
                  )}

                  <DoctorsNote
                    appointmentId={appointmentId || id || ""}
                    doctorNotes={summaryDetails?.doctorNotes}
                  />

                  <Medication
                    appointmentId={appointmentId || id || ""}
                    medications={summaryDetails?.prescription}
                  />

                  <Box
                    sx={{
                      marginTop: "6.25rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="primary-button"
                      onClick={handleCreateSummary}
                      disabled={createSummaryLoading}
                      sx={{ marginRight: "0.875rem" }}
                    >
                      Save Prescription
                    </Button>

                    {!showCloseButton && (
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        onClick={() => setOpenConfirmModal(true)}
                      >
                        Close Meeting
                      </Button>
                    )}

                    {showCloseButton && (
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        onClick={() => {
                          closeButtonHandler && closeButtonHandler();
                        }}
                      >
                        Close
                      </Button>
                    )}

                    <ConfirmModal
                      open={openConfirmModal}
                      description="Are you sure, you want to close the meeting?"
                      handleConfirm={handleConfirm}
                      handleCancel={handleCancel}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Meet;
