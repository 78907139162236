import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";

import DocumentList from "./DocumentList";
import OriginalReport from "./OriginalReport";
import ReportSummary from "./ReportSummary";
import useAuthStore from "../../../store/authStore";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import { DocumentsProps } from "../../../types/documents";
import { isPatient } from "../../../utils/auth";
import FullscreenModal from "../../shared/FullscreenModal";

const ReportViewer: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const isBelow800 = useMediaQuery("(max-width:50rem)");

  const {
    isMenuCollapsed,
    setIsMenuCollapsed,
    setDocumentsConferenceView,
    setDrawerOpen,
  } = useAppStore();

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  const [isDocumentsCollapsed, setIsDocumentsCollapsed] =
    useState<boolean>(false);
  const [isPreviewCollapsed, setIsPreviewCollapsed] = useState<boolean>(false);
  const [isSummaryCollapsed, setIsSummaryCollapsed] = useState<boolean>(false);

  const {
    documents,
    reGeneratedSummaryData,
    cleanUpReGenerateSummaryState,
    documentDetails,
    loadingDocumentDetails,
    errorDocumentDetails,
    fetchDocumentDetails,
    cleanUpDocumentDetailsStore,
    selectedDocumentId,
    setSelectedDocumentId,
  } = useDocumentStore();

  console.log("documentDetails", documentDetails);

  const [fullScreenModalOpen] = useState(true);

  useEffect(() => {
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      cleanUpDocumentDetailsStore();
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (selectedDocumentId) {
      fetchDocumentDetails(selectedDocumentId);
      cleanUpReGenerateSummaryState();
    }
  }, [selectedDocumentId, reGeneratedSummaryData]);

  useEffect(() => {
    if (documents && documents.length > 0 && selectedDocumentId) {
      const document = documents.filter((doc) => doc.id === selectedDocumentId);
      if (document.length === 0) {
        setSelectedDocumentId(documents[0]?.id);
      }
    }
  }, [documents, selectedDocumentId]);

  // redirect to documents page if no documents
  useEffect(() => {
    if (documents && documents.length === 0) {
      if (patientId) {
        if (from === "meet") {
          setDocumentsConferenceView("List");
        } else {
          navigate(`/patients/${patientId}/documents`);
        }
      } else {
        navigate("/documents");
      }
    }
  }, [documents]);

  return (
    <FullscreenModal open={fullScreenModalOpen}>
      <Box
        sx={{
          backgroundColor: "var(--neuro-white-text)",
          height: "100%",
          padding: "1.25rem",
          paddingBottom: 0,
        }}
      >
        <Grid
          container
          spacing={2}
          flexWrap="wrap"
          sx={{ height: "100%", overflow: "auto" }}
        >
          {isPatient(userRole) && (
            <>
              <Grid
                item
                xs={12}
                md={
                  isMenuCollapsed
                    ? from === "meet"
                      ? 12
                      : 3.5
                    : from === "meet"
                      ? 12
                      : 4
                }
                // xl={from === "meet" ? 12 : 3}
                sx={{
                  height: isBelow800 ? "auto" : "100%",
                  overflowY: "hidden",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <DocumentList patientId={patientId} />
              </Grid>

              <Grid
                item
                xs={12}
                md={
                  isMenuCollapsed
                    ? from === "meet"
                      ? 12
                      : 8.5
                    : from === "meet"
                      ? 12
                      : 8
                }
                // xl={from === "meet" ? 12 : 9}
                sx={{
                  height: "100%",
                  overflowY: "hidden",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <OriginalReport
                  key={documentDetails?.id}
                  loading={loadingDocumentDetails}
                  error={Boolean(errorDocumentDetails)}
                  fileType={documentDetails?.fileType}
                  filePath={documentDetails?.filePath}
                  fileName={documentDetails?.name}
                  id={documentDetails?.id}
                  status={documentDetails?.status}
                  patientId={patientId}
                />
              </Grid>
            </>
          )}

          {!isPatient(userRole) && (
            <>
              <Grid
                item
                xs={12}
                md={
                  isDocumentsCollapsed && isSummaryCollapsed && isPreviewCollapsed
                    ? 4
                    : isDocumentsCollapsed
                      ? 0.75
                      : isMenuCollapsed
                        ? isPreviewCollapsed && isSummaryCollapsed
                          ? 10.5
                          : isPreviewCollapsed || isSummaryCollapsed
                            ? 3
                            : 3
                        : isPreviewCollapsed && isSummaryCollapsed
                          ? 10.5
                          : isPreviewCollapsed || isSummaryCollapsed
                            ? 3.5
                            : 3.5
                }
                sx={{
                  height: "100%",
                  overflowY: "hidden",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <DocumentList
                  patientId={patientId}
                  from={from}
                  isDocumentsCollapsed={isDocumentsCollapsed}
                  setIsDocumentsCollapsed={setIsDocumentsCollapsed}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={
                  isDocumentsCollapsed && isSummaryCollapsed && isPreviewCollapsed
                    ? 4
                    : isPreviewCollapsed
                      ? 0.75
                      : isMenuCollapsed
                        ? isDocumentsCollapsed && isSummaryCollapsed
                          ? 10.5
                          : isDocumentsCollapsed || isSummaryCollapsed
                            ? isSummaryCollapsed
                              ? 8.25
                              : 5.5
                            : 4.75
                        : isDocumentsCollapsed && isSummaryCollapsed
                          ? 10.5
                          : isDocumentsCollapsed || isSummaryCollapsed
                            ? isSummaryCollapsed
                              ? 7.75
                              : 5.5
                            : 4.75
                }
                sx={{
                  height: "100%",
                  overflowY: "hidden",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <OriginalReport
                  loading={loadingDocumentDetails && !documentDetails}
                  error={Boolean(errorDocumentDetails) && !documentDetails}
                  fileType={documentDetails?.fileType}
                  filePath={documentDetails?.filePath}
                  fileName={documentDetails?.name}
                  id={documentDetails?.id}
                  status={documentDetails?.status}
                  patientId={patientId}
                  isPreviewCollapsed={isPreviewCollapsed}
                  setIsPreviewCollapsed={setIsPreviewCollapsed}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={
                  isDocumentsCollapsed && isSummaryCollapsed && isPreviewCollapsed
                    ? 4
                    : isSummaryCollapsed
                      ? 0.75
                      : isMenuCollapsed
                        ? isDocumentsCollapsed && isPreviewCollapsed
                          ? 10.5
                          : isDocumentsCollapsed || isPreviewCollapsed
                            ? isPreviewCollapsed
                              ? 8.25
                              : 5.75
                            : 4.25
                        : isDocumentsCollapsed && isPreviewCollapsed
                          ? 10.5
                          : isDocumentsCollapsed || isPreviewCollapsed
                            ? isPreviewCollapsed
                              ? 7.75
                              : 5.75
                            : 3.75
                }
                sx={{
                  height: "100%",
                  overflowY: "hidden",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <ReportSummary
                  loading={loadingDocumentDetails}
                  id={documentDetails?.id}
                  metadata={documentDetails?.metadata}
                  doctorSummary={documentDetails?.doctorSummary}
                  patientSummary={documentDetails?.patientSummary}
                  fileName={documentDetails?.name}
                  fileType={documentDetails?.fileType}
                  isDoctorApproved={documentDetails?.isDoctorApproved}
                  feedback={documentDetails?.feedback}
                  transcriptions={documentDetails?.transcriptions}
                  patientNote={documentDetails?.patientNote}
                  symptomAssessment={documentDetails?.symptomAssessment}
                  isSummaryCollapsed={isSummaryCollapsed}
                  setIsSummaryCollapsed={setIsSummaryCollapsed}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </FullscreenModal>
  );
};

export default ReportViewer;
