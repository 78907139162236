import React, { useState } from 'react';
import { FaCopy, FaTrash, FaCheckCircle, FaRegCircle } from 'react-icons/fa';
// import './switchgroup.css';

interface DynamicBooleanSwitchGroupProps {
  question: {
    type: string;
    name: string;
    title?: string; // Add this line to include the title property
    labelTrue?: string;
    labelFalse?: string;
    required?: boolean;
    correctAnswer?: string;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
}

const DynamicBooleanSwitchGroup: React.FC<DynamicBooleanSwitchGroupProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [switchState, setSwitchState] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>(question.correctAnswer || "");
  const [editableQuestionName, _] = useState<string>(question.name);
  const [editableLabelTrue, setEditableLabelTrue] = useState<string>(question.labelTrue || "Yes");
  const [editableLabelFalse, setEditableLabelFalse] = useState<string>(question.labelFalse || "No");
  const [localQuestion, setLocalQuestion] = useState(question);

  const handleSwitchChange = () => {
    const newSwitchState = !switchState;
    setSwitchState(newSwitchState);
    setAnswer(newSwitchState ? editableLabelTrue : editableLabelFalse);
  };



  const handleLabelTrueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableLabelTrue(e.target.value);
    updateQuestion(index, { ...question, labelTrue: e.target.value });
  };

  const handleLabelFalseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableLabelFalse(e.target.value);
    updateQuestion(index, { ...question, labelFalse: e.target.value });
  };

  const handleLabelTrueDoubleClick = () => {
    setAnswer(editableLabelTrue); // Set the answer to the label for True
    updateQuestion(index, { ...question, correctAnswer: editableLabelTrue });
  };

  const handleLabelFalseDoubleClick = () => {
    setAnswer(editableLabelFalse); // Set the answer to the label for False
    updateQuestion(index, { ...question, correctAnswer: editableLabelFalse });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
    updateQuestion(index, { ...question, title: newTitle });
  };

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleRequiredToggle = () => {
    toggleRequired(index);
  };

  return (
    <div className="question-container">
      <div className="question-header"  style={{marginBottom:'10px'}}>
        {/* <div className="question-" style={{marginBottom:'10px'}}> */}
          {/* Title Field */}
         {editableQuestionName}

          {/* Title Field */}
          <input
            type="text"
            value={localQuestion.title || ""}
            onChange={handleTitleChange}
            placeholder="Enter title"
            className="question-name-input"
          />
        {/* </div> */}
      </div>

      <div className="switch-container">
        <input
          type="text"
          value={editableLabelTrue}
          onChange={handleLabelTrueChange}
          placeholder={`True ${editableLabelTrue}`}
          className="label-input"
          onDoubleClick={handleLabelTrueDoubleClick} // Double-click to set the answer
        />
        <input
          type="text"
          value={editableLabelFalse}
          onChange={handleLabelFalseChange}
          placeholder={`False ${editableLabelFalse}`}
          className="label-input"
          onDoubleClick={handleLabelFalseDoubleClick} // Double-click to set the answer
        />

        <input
          type="checkbox"
          checked={switchState}
          onChange={handleSwitchChange}
          className="switch-checkbox"
        />
      </div>

      <div className="answer-field">
        <label>Answer:</label>
        <input
          type="text"
          value={answer}
          disabled={true} // Disable the input field to make it read-only
          className="answer-input"
        />
      </div>

      <div className="question-footer"   style={{marginTop:'10px'}}>
        <button onClick={() => handleDuplicateQuestion(index)} className="duplicate-button button-spacing blue-bg duplicate-button button-spacing blue-bg">
          <FaCopy style={{ color: 'orange' }} /> Duplicate
        </button>

        <button onClick={() => handleRemoveQuestion(index)} className="delete-button button-spacing red-bg required-button">
          <FaTrash style={{ color: 'orange' }} /> Delete
        </button>

        <button onClick={handleRequiredToggle} className="required-button ">
          {question.required ? <FaCheckCircle /> : <FaRegCircle />}
          {question.required ? ' Required' : ' Not Required'}
        </button>
      </div>
    </div>
  );
};

export default DynamicBooleanSwitchGroup;
