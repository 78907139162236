import React, { useState } from 'react';
import { FaCopy, FaTrash, FaStar, FaRegStar } from 'react-icons/fa';
// import './imagepic.css'; // Ensure your styles are in place

interface DynamicImagePickerProps {
  initialQuestion: {
    type: string;
    name: string;
    title: string;
    correctAnswer: string[];  // Array for multiple correct answers
    choices: { value: string; imageLink: string }[];
    imageFit: 'cover' | 'contain';
    isRequired: boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
}

const DynamicImagePicker: React.FC<DynamicImagePickerProps> = ({
  initialQuestion,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState(initialQuestion);

  const updateLocalQuestion = (updatedQuestion: typeof initialQuestion) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  // Ensure correctAnswer is always an array
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  // Ensure correctAnswer is always an array



  const handleImageDelete = (idx: number) => {
    setTimeout(() => {
      const updatedChoices = localQuestion.choices.filter((_, choiceIdx) => choiceIdx !== idx);
      const updatedCorrectAnswer = localQuestion.correctAnswer.filter(
        (answer) => answer !== localQuestion.choices[idx].value
      );
      setLocalQuestion({
        ...localQuestion,
        choices: updatedChoices,
        correctAnswer: updatedCorrectAnswer, // Remove the answer from correctAnswer array
      });
    }, 300); // Simulate a delay (300ms here, you can adjust this time)
  };


  // Handle image upload for a specific choice
  const handleImageUpload = (idx: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImageLink = URL.createObjectURL(file);
      const updatedChoices = localQuestion.choices.map((choice, choiceIdx) =>
        choiceIdx === idx ? { ...choice, imageLink: newImageLink } : choice
      );
      setLocalQuestion({ ...localQuestion, choices: updatedChoices });
    }
  };

  // const handleImageClick = (value: string) => {
  //   setLocalQuestion({
  //     ...localQuestion,
  //     correctAnswer: Array.isArray(localQuestion.correctAnswer)
  //       ? [...localQuestion.correctAnswer, value]
  //       : [value], // Ensure correctAnswer is always an array
  //   });
  // };

  const handleImageClick = (value: string) => {
    console.log('Selected value:', value); // Log the clicked image value
    console.log('Current correct answers:', localQuestion.correctAnswer); // Log the current correct answers
  
    setLocalQuestion((prevState) => {
      const updatedCorrectAnswer = Array.isArray(prevState.correctAnswer)
        ? prevState.correctAnswer.includes(value)
          ? prevState.correctAnswer.filter((answer) => answer !== value) // Remove answer if already selected
          : [...prevState.correctAnswer, value] // Add answer if not already in the list
        : [value]; // Fallback if correctAnswer is not an array
    
      console.log('Updated correct answers:', updatedCorrectAnswer); // Log the updated correct answers
      
      return {
        ...prevState,
        correctAnswer: updatedCorrectAnswer,
      };
    });
  };
  
  
  

  return (
    <div className="question-container">
      <div className="image-picker-container" style={{ width: '80%' }}>
       <div  style={{marginBottom:'0.8rem'}}>
        {/* Editable Question Title */}
           {localQuestion.name}  {" "} 
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          placeholder="Enter Question Title"
          className="question-name-input"
        />
</div>
        {/* Image Picker Choices */}
        <div className="image-picker-grid">
          {localQuestion.choices.map((choice, idx) => (
            <div
              key={idx}
              className={`image-picker-item ${
                localQuestion.correctAnswer.includes(choice.value) ? 'selected' : ''
              }`}
              onClick={() => handleImageClick(choice.value)}
              style={{
                backgroundImage: `url(${choice.imageLink})`,
                backgroundSize: localQuestion.imageFit,
              }}
            >
              <span className="image-picker-label">{choice.value}</span>
              <div className="image-picker-buttons">
                <label className="circle-button upload-button">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(idx, e)}
                  />
                  📤
                </label>

                <button
                  className="circle-button delete-button"
                  onClick={() => handleImageDelete(idx)} // Delayed delete
                >
                  ❌
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Correct Answer Display */}
        <div className="correct-answer-input">
          <label style={{ fontSize: '14px' }}>Selected Answer(s):</label>
          <div className="selected-answers">
            {Array.isArray(localQuestion.correctAnswer) && localQuestion.correctAnswer.length > 0 ? (
              localQuestion.correctAnswer.map((answer, idx) => {
                const choice = localQuestion.choices.find((c) => c.value === answer);
                return (
                  <div key={idx} className="selected-answer-item">
                    <span>{choice?.value || answer}</span>
                    <button
                      className="remove-button"
                      onClick={() => {
                        // Remove the selected answer
                        const updatedAnswers = localQuestion.correctAnswer.filter((ans) => ans !== answer);
                        updateLocalQuestion({ ...localQuestion, correctAnswer: updatedAnswers });
                      }}
                    >
                      x
                    </button>
                  </div>
                );
              })
            ) : (
              <span>No answers selected</span>
            )}
          </div>
        </div>

        {/* Controls */}
        <div className="image-picker-controls">
          <button
            className="duplicate-button button-spacing blue-bg duplicate-button button-spacing blue-bg"
            onClick={() => handleDuplicateQuestion(index)}
          >
            <FaCopy style={{ color: 'orange', marginRight: '5px' }} /> Duplicate
          </button>

          <button
            className="delete-button button-spacing red-bg required-button"
            onClick={() => handleRemoveQuestion(index)}
          >
            <FaTrash style={{ color: 'orange', marginRight: '5px' }} /> Delete
          </button>

          <button
            className={`required-button ${localQuestion.isRequired ? 'active' : ''}`}
            onClick={() => toggleRequired(index)}
          >
            {localQuestion.isRequired ? (
              <FaStar style={{ color: 'orange', marginRight: '5px' }} />
            ) : (
              <FaRegStar style={{ color: 'orange', marginRight: '5px' }} />
            )}
            {localQuestion.isRequired ? 'Required' : 'Optional'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicImagePicker;
