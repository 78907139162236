import React, { useState, useEffect } from "react";
import { FaCopy, FaTrash, FaStar, FaRegStar } from "react-icons/fa";
// import "./component.css";
// import './compo.css';

interface Choice {
  value: string | boolean;
  text: string;
  score?: number;
}

interface RadioGroupQuestion {
  type: string;
  name: string;
  title: string;
  choices: Choice[];
  isRequired: boolean;
}

interface DynamicRadioGroupProps {
  question: RadioGroupQuestion;
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: RadioGroupQuestion) => void;
}

const DynamicRadioGroup: React.FC<DynamicRadioGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState<RadioGroupQuestion>(question);

  // Sync local state with parent prop changes
  useEffect(() => {
    setLocalQuestion(question);
  }, [question]);

  // Debounced update to parent component
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 200);
    return () => clearTimeout(timeout);
  }, [localQuestion, index, updateQuestion]);

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
  };

  // Handle required toggle
  const handleToggleRequired = () => {
    setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
  };

  // Handle choices update
  const handleChoiceChange = (
    choiceIndex: number,
    field: keyof Choice,
    value: string | boolean | number | undefined
  ) => {
    const updatedChoices = localQuestion.choices.map((choice, idx) =>
      idx === choiceIndex ? { ...choice, [field]: value } : choice
    );
    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  // Handle radio button selection
  const handleRadioSelect = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.map((choice, idx) =>
      idx === choiceIndex ? { ...choice, value: true } : { ...choice, value: false }
    );
    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  // Add a new choice
  const addChoice = () => {
    setLocalQuestion((prev) => ({
      ...prev,
      choices: [
        ...prev.choices,
        { value: false, text: "New Choice", score: undefined },
      ],
    }));
  };

  // Remove a choice
  const removeChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter(
      (_, idx) => idx !== choiceIndex
    );
    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  return (
    <div className="question-container">
      {/* Title Input */}
      <div className="question-header">
      {localQuestion.name} {" "}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}      
          className="question-name-input"
          placeholder="Enter question title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Choices */}
      <div className="radio-group-choices">
        {localQuestion.choices.map((choice, idx) => ( 
          <div key={idx} className="choice-item">
               <button
              className="remove-button"
              onClick={() => removeChoice(idx)}
            > -
              {/* <FaTrash style={{ color: "red" }} /> */}
            </button>
            <input
              type="radio"
              name={`question-${index}`} // Ensures only one radio is selected at a time
              checked={choice.value === true}
              onChange={() => handleRadioSelect(idx)} // Handles radio selection
              className="radio"
            />
            <input
              type="text"
              value={choice.text}
              onChange={(e) =>
                handleChoiceChange(idx, "text", e.target.value)
              }
              placeholder="Choice text"
              className="edit-label-input"
            />
            {/* <input
              type="number"
              value={choice.score || ""}
              onChange={(e) =>
                handleChoiceChange(
                  idx,
                  "score",
                  e.target.value ? parseFloat(e.target.value) : undefined
                )
              }
              placeholder="Score"
              className="choice-score-input"
            /> */}
         
          </div>
        ))}
        <button className="add-button" onClick={addChoice}>
          +
        </button>
      </div>

      {/* Control Buttons */}
      <div className="radio-group-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          <FaCopy style={{ color: "orange" }} /> Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          <FaTrash style={{ color: "orange" }} /> Delete
        </button>

        <button
          className={`required-button ${
            localQuestion.isRequired ? "active1" : ""
          }`}
          onClick={handleToggleRequired}
          aria-pressed={localQuestion.isRequired}
        >
          {localQuestion.isRequired ? (
            <FaStar style={{ color: "orange", fontSize: "20px" }} />
          ) : (
            <FaRegStar style={{ color: "orange" }} />
          )}
          {localQuestion.isRequired ? " Required" : " Not Required"}
        </button>
      </div>
    </div>
  );
};

export default DynamicRadioGroup;
