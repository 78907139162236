import React, { useState, useEffect, useCallback } from "react";
import { fetchPatients } from "../../services/patientService";
import {
  Box,
  Typography,
  //  useMediaQuery
} from "@mui/material";
import { Patient } from "../../types/common";
import {
  useLocation,
  //  useNavigate
} from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
// import HorizontalTabs from "../profile/patient/HorizontalTabs";
// import { capitalizeFirstLetter } from "../../utils/common";
import AddIcon from "@mui/icons-material/Add";
import PatientSignUpModal from "./PatientSignUpModal";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import PatientsList from "./PatientsList";
import ContentCenter from "../shared/ContentCenter";
// import PatientTabs from "./PatientTabs";
// import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import useAppStore from "../../store/appStore";
import CardTableToggle from "../shared/CardTableToggle";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
// import config from "../../config";
import useAuthStore from "../../store/authStore";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import PatientCard from "./PatientCard";

const Patients: React.FC = () => {
  // props & state values
  // const isMobile = useMediaQuery("(max-width:37.5rem)");
  const location = useLocation();
  // const navigate = useNavigate();
  const {
    patientsFilter,
    setFilter,
    selectedPatientInList,
    setSelectedPatientInList,
  } = useAppStore();
  const { userRole } = useAuthStore();

  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // const [selectedPatient, setSelectedPatient] = useState<any>(null);

  console.log("patients", patients);

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("patientsView") || "table"
  );

  const [addNewPatientModalOpen, setAddNewPatientModalOpen] =
    useState<boolean>(false);

  // const tabs = [
  //   "Profile",
  //   "Documents",
  //   "Appointments",
  //   "Forms",
  //   "ABHA Details",
  //   "Audit Logs",
  // ];

  // const [selectedTab, setSelectedTab] = useState<string>("Profile");
  // const [showProfileEdit, setShowProfileEdit] = useState<boolean>(false);

  const showPatientsList = location?.pathname === "/patients";

  // const breadcrumbItems = [
  //   { label: "Patients", href: "/patients" },
  //   {
  //     label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
  //   },
  //   { label: selectedTab },
  // ];

  // callbacks & functions
  // function getInitialTab() {
  //   if (location.pathname.toLowerCase().includes("abha-details")) {
  //     return "ABHA Details";
  //   }

  //   if (location.pathname.toLowerCase().includes("audit-logs")) {
  //     return "Audit Logs";
  //   }
  //   const foundTab = tabs.find((tab) =>
  //     location.pathname.toLowerCase().includes(tab.toLowerCase())
  //   );
  //   return foundTab || "Profile";
  // }

  // useEffect(() => {
  //   const newTab = getInitialTab();
  //   setSelectedTab(newTab);
  // }, [location.pathname]);

  // const onTabClick = (tab: string) => {
  //   setSelectedTab(tab);
  //   if (selectedPatientInList) {
  //     if (tab === "ABHA Details") {
  //       navigate(`/patients/${selectedPatientInList.id}/abha-details`);
  //     } else if (tab === "Audit Logs") {
  //       console.log("im here");
  //       navigate(`/patients/${selectedPatientInList.id}/audit-logs`);
  //     } else {
  //       navigate(`/patients/${selectedPatientInList.id}/${tab.toLowerCase()}`);
  //     }
  //   }
  // };

  // const handleProfileViewEdit = () => {
  //   setShowProfileEdit((prev) => !prev);
  // };

  useEffect(() => {
    setSelectedPatientInList(null);

    // return () => {
    //   setSelectedPatientInList(null);
    // };
  }, []);

  const loadPatients = async (
    start: string | null,
    end: string | null,
    search: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchPatients(start, end, search);
      setPatients(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPatients(
      patientsFilter.customStartDate,
      patientsFilter.customEndDate,
      patientsFilter.searchValue || ""
    );
  }, [
    location?.pathname,
    patientsFilter.customStartDate,
    patientsFilter.customEndDate,
  ]);

  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    loadPatients(filter.customStartDate, filter.customEndDate, value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
      handleInputChange(value, filter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("patientsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, patientsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all appointments
    //   handleInputChange("", patientsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, patientsFilter);
    // }
  };
  // search filter handlers end

  const handleAddNew = () => {
    setAddNewPatientModalOpen(true);
  };

  const updatePatientsRangeFilter = (value: string) => {
    setFilter("patientsFilter", {
      range: value,
    });
  };

  const updatePatientsCustomDateFilter = (start: string, end: string) => {
    setFilter("patientsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("patientsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {showPatientsList && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ComponentHeader title="Patient List" />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              {isClinicAdminOrAdmin(userRole) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="outlined-secondary-button"
                  startIcon={<AddIcon />}
                  onClick={handleAddNew}
                >
                  Add New
                </Button>
              )}
            </Box>
          </Box>
        )}

        {showPatientsList && (
          <Box sx={{ marginBottom: "1rem" }}>
            <ComponentFilter
              updateRange={updatePatientsRangeFilter}
              updateDates={updatePatientsCustomDateFilter}
              range={patientsFilter.range}
              isEmpty
              viewComponent={
                <CardTableToggle view={view} setView={handleToggleView} />
              }
              hideStatusFilter
              handleStoreSearchValueChange={handleStoreSearchValueChange}
              searchValue={patientsFilter.searchValue || ""}
              searchFilterPlaceholder="MRN, Name, Email, Mobile, ABHA, Aadhar"
              searchFilterWidth="22.7rem"
            />
          </Box>
        )}

        {/* {!showPatientsList && <CustomBreadcrumbs items={breadcrumbItems} />} */}

        {/* {!showPatientsList && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              gap: "0.625rem",
            }}
          >
            <HorizontalTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onClick={onTabClick}
              from="admin"
            />

            {selectedTab === "Profile" && (
              <Button
                variant="outlined"
                color="secondary"
                className="outlined-secondary-button"
                onClick={handleProfileViewEdit}
                sx={{
                  flexShrink: 0,
                  height: "2.25rem",
                }}
              >
                {showProfileEdit ? "View Profile" : "Edit Profile"}
              </Button>
            )}
          </Box>
        )} */}
      </Box>

      {/* {!showPatientsList && (
        <PatientTabs
          selectedPatient={selectedPatientInList}
          selectedTab={selectedTab}
          from="admin"
          showProfileEdit={showProfileEdit}
        />
      )} */}

      {showPatientsList && (
        <Box sx={{ width: "100%", height: "calc(100% - 8rem)" }}>
          <>
            {loading && <LoadingScreen />}

            {!loading && patients && patients.length === 0 && (
              <ContentCenter>
                <Typography className="no-data">No patients found</Typography>
              </ContentCenter>
            )}

            {!loading &&
              patients &&
              patients.length > 0 &&
              (view === "card" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    maxHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  {patients?.map((patient) => (
                    <PatientCard
                      key={patient.id}
                      patient={patient}
                      selectedPatient={selectedPatientInList}
                      setSelectedPatient={setSelectedPatientInList}
                    />
                  ))}
                </Box>
              ) : (
                <PatientsList
                  patients={patients}
                  selectedPatient={selectedPatientInList}
                  setSelectedPatient={setSelectedPatientInList}
                />
              ))}

            {/* new patient add modal */}
            <PatientSignUpModal
              open={addNewPatientModalOpen}
              onClose={() => setAddNewPatientModalOpen(false)}
              loadPatients={loadPatients}
            />
          </>
        </Box>
      )}
    </Box>
  );
};

export default Patients;
