import React from "react";
import { Box } from "@mui/material";
import { SidebarContentProps } from "../../types/common";
import useAuthStore from "../../store/authStore";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import SidebarSubMenu from "./SidebarSubMenu";

const SubmenuSidebarContent: React.FC<SidebarContentProps> = ({
  menuItems,
}) => {
  // props & state values
  const { userRole } = useAuthStore();

  return (
    <Box
      sx={{
        // width: "13.75rem",
        // minWidth: "13.75rem",
        width: "11rem",
        minWidth: "11rem",
        transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        backgroundColor: "var(--neuro-white-text)",
        height: "100%",
        color: "var(--neuro-black-text)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "0.063rem solid var(--neuro-secondary_border)",
        overflow: "hidden",

        // width: "11.5rem",
        // minWidth: "11.5rem",
        // transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        // backgroundColor: "var(--neuro-white-text)",
        // height: "fit-content",
        // color: "var(--neuro-black-text)",
        // display: "flex", // Flexbox
        // flexDirection: "column", // Column direction
        // justifyContent: "space-between", // Space between items
        // border: "0.063rem solid var(--neuro-secondary_border)", // Border
        // overflow: "hidden", // Prevent overflow
        // margin: "5px", // Margin
      }}
    >
      <Box
        sx={{
          height: isClinicAdminOrAdmin(userRole)
            ? "100%"
            : "calc(100% - 11.25rem)",
        }}
      >
        <SidebarSubMenu
          menuItems={menuItems}
          showIconsOnly={false}
          usedInSubMenu
        />
      </Box>
    </Box>
  );
};

export default SubmenuSidebarContent;
