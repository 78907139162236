import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../ui/Button";
// import { parseISO, setHours, setMinutes } from "date-fns";
import { getFormattedDateTime } from "../../../utils/appointments";
import {
  // getDoctorsForDate,
  getTimeSlotsForDate,
  getTimeSlotsForDoctor,
} from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  CategorizedTimeSlots,
  TimeSlotCategory,
  TimeSlotProps,
} from "../../../types/appointments";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";

const TimeSlot: React.FC<TimeSlotProps> = ({ time, isSelected, onClick }) => (
  <Button
    variant="outlined"
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClick();
    }}
    sx={{
      borderRadius: "0.625rem",
      width: "5.625rem",
      height: "2.875rem",
      border: "1px solid var(--neuro-secondary_border)",
      color: isSelected ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      backgroundColor: isSelected
        ? "var(--neuro-button-bg-primary)"
        : "var(--neuro-white-text)",
      "&:hover": {
        backgroundColor: isSelected
          ? "var(--neuro-button-bg-primary)"
          : "var(--neuro-white-text)",
        border: "1px solid var(--neuro-secondary_border)",
      },
    }}
  >
    {time}
  </Button>
);

const AppointmentTimeSlots = () => {
  // props & state values
  const {
    selectedMeetingType,
    selectedDoctor,
    setAppointmentDate,
    yourAvailabilitySelected,
    selectedDate,
    selectedTime,
    setSelectedTime,
    setAvailableSlotId,
  } = useAppointmentStore();

  console.log("selectedMeetingType", selectedMeetingType);

  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [dateTimeSlots, setDateTimeSlots] = useState<any[]>([]);

  const [dateTimeSlotsLoading, setDateTimeSlotsLoading] =
    useState<boolean>(false);

  // const [loadingAvailableDoctors, setLoadingAvailableDoctors] =
  //   useState<boolean>(false);

  // const [availableDoctors, setAvailableDoctors] = useState<any[]>([]);

  const [categorizedTimeSlots, setCategorizedTimeSlots] =
    useState<CategorizedTimeSlots>({
      morning: [],
      afternoon: [],
      evening: [],
    });

  const [categorizedDateTimeSlots, setCategorizedDateTimeSlots] =
    useState<CategorizedTimeSlots>({
      morning: [],
      afternoon: [],
      evening: [],
    });

  // callbacks & functions
  const loadTimeSlotsForDate = async (meetingType: string, date: string) => {
    const params = {
      meetingType,
      date,
    };
    setDateTimeSlotsLoading(true);
    try {
      const response = await getTimeSlotsForDate(params);
      setDateTimeSlots(response?.data?.availableSlots || []);
      setDateTimeSlotsLoading(false);
    } catch (error) {
      setDateTimeSlotsLoading(false);
    }
  };

  // const loadAvailableDoctors = async (
  //   meetingType: string,
  //   startTime: string
  // ) => {
  //   const params = {
  //     meetingType,
  //     startTime,
  //   };
  //   try {
  //     const response = await getDoctorsForDate(params);
  //     setAvailableDoctors(response?.data || []);
  //     setLoadingAvailableDoctors(false);
  //   } catch (error) {
  //     setLoadingAvailableDoctors(false);
  //   }
  // };

  const onTimeSelect = (time: any) => {
    setSelectedTime(time?.formattedTime);
    setAppointmentDate(time?.startTime);
  };

  const handleTimeSlotClick = (timeSlot: any) => {
    onTimeSelect(timeSlot);
    setAvailableSlotId(timeSlot.slotId);
  };

  const handleDateTimeSlotClick = (timeSlot: any) => {
    onTimeSelect(timeSlot);
  };

  const getTimeSlotInFormat = (dateString: string) => {
    // Parse the UTC time string to a Date object
    const dateTimeObj: any = getFormattedDateTime(dateString);

    const utrcFormatedTIme =
      dateTimeObj.hoursWithoutZero +
      ":" +
      dateTimeObj.minutes +
      dateTimeObj.amOrPm;
    return utrcFormatedTIme;
  };

  const getDateFormatForApi = (inputDateString: string) => {
    const inputDate = new Date(inputDateString);

    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const date = inputDate.getDate();

    const localMidnight = new Date(year, month, date, 0, 0, 0, 0);

    const utcOffset = localMidnight.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const utcMidnight = new Date(localMidnight.getTime() - utcOffset);

    const isoDateString = utcMidnight.toISOString();

    return isoDateString;
  };

  // const combineDateAndTime = (
  //   dateString: string,
  //   timeString: string
  // ): string => {
  //   const date = parseISO(dateString);

  //   const timeMatch = timeString.match(/^(\d{1,2}):(\d{2})(am|pm)$/i);
  //   if (!timeMatch) {
  //     throw new Error("Invalid time format");
  //   }

  //   let [, hoursStr, minutesStr, period] = timeMatch;
  //   let hours = parseInt(hoursStr, 10);
  //   const minutes = parseInt(minutesStr, 10);

  //   if (period.toLowerCase() === "pm" && hours !== 12) {
  //     hours += 12;
  //   }
  //   if (period.toLowerCase() === "am" && hours === 12) {
  //     hours = 0;
  //   }

  //   const combinedDate = setHours(setMinutes(date, minutes), hours);

  //   const utcOffset = combinedDate.getTimezoneOffset() * 60000;
  //   const utcDate = new Date(combinedDate.getTime() - utcOffset);

  //   const isoDateString = utcDate.toISOString();

  //   return isoDateString;
  // };

  const loadTimeSlotsForDoctor = async (
    id: string,
    params: { meetingType: string; date: string }
  ) => {
    try {
      const response = await getTimeSlotsForDoctor(id, params);
      console.log("reponse in time slots", response);
      setTimeSlots(response?.data?.availableSlots || []);
      setLoading(false);
    } catch (error) {
      setTimeSlots([]);
      console.error("Error fetching time slots:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !yourAvailabilitySelected &&
      selectedDoctor &&
      selectedMeetingType &&
      selectedDate
    ) {
      const date = getDateFormatForApi(`${selectedDate}`);
      const params = {
        meetingType: selectedMeetingType,
        date,
      };
      console.log("loading time slots..");
      loadTimeSlotsForDoctor(selectedDoctor?.id, params);
    }
  }, [
    yourAvailabilitySelected,
    selectedDoctor,
    selectedMeetingType,
    selectedDate,
  ]);

  // from your availabilty selected time slot
  // useEffect(() => {
  //   if (selectedTimeSlotId && timeSlots && timeSlots.length > 0) {
  //     const timeSlot = timeSlots.filter(
  //       (slot: SlotProps) => slot.slotId === selectedTimeSlotId
  //     );
  //     if (timeSlot && timeSlot.length > 0) {
  //       const formattedTime = getTimeSlotInFormat(timeSlot[0]?.startTime);
  //       onTimeSelect(formattedTime);
  //       // setAvailableSlotId(selectedTimeSlotId);
  //     }
  //   }
  // }, [selectedTimeSlotId, timeSlots]);

  // for your availabilty time slots
  useEffect(() => {
    if (yourAvailabilitySelected && selectedMeetingType && selectedDate) {
      const date = getDateFormatForApi(`${selectedDate}`);
      console.log("loading time slots..");
      loadTimeSlotsForDate(selectedMeetingType, date);
    }
  }, [yourAvailabilitySelected, selectedMeetingType, selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      onTimeSelect(null);
      if (!yourAvailabilitySelected) {
        setAvailableSlotId(null);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedMeetingType) {
      onTimeSelect(null);
    }
  }, [selectedMeetingType]);

  function categorizeTimeSlot(timeSlot: string): TimeSlotCategory {
    const match = timeSlot.match(/^(\d{1,2}:\d{2})(am|pm)$/i);
    if (!match) {
      throw new Error("Invalid time slot format");
    }
    const [, time, period] = match;
    let [hours] = time.split(":").map(Number);

    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    }
    if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    if (hours >= 0 && hours < 12) {
      return "morning"; // 12:00 am to 11:59 am
    } else if (hours >= 12 && hours < 18) {
      return "afternoon"; // 12:00 pm to 5:59 pm
    } else {
      return "evening"; // 6:00 pm to 11:59 pm
    }
  }

  useEffect(() => {
    if (timeSlots && timeSlots.length > 0) {
      const categorized = timeSlots?.reduce<CategorizedTimeSlots>(
        (acc, timeSlot) => {
          // const startTime = timeSlot.startTime;
          const formattedTime = getTimeSlotInFormat(timeSlot?.startTime);
          const category = categorizeTimeSlot(formattedTime);

          if (acc[category]) {
            acc[category].push({
              ...timeSlot,
              formattedTime, // Include formattedTime in the timeSlot object
            });
          }

          return acc;
        },
        {
          morning: [],
          afternoon: [],
          evening: [],
        }
      );

      setCategorizedTimeSlots(categorized);
    }
  }, [timeSlots]);

  // for your availability time slots
  useEffect(() => {
    if (dateTimeSlots && dateTimeSlots.length > 0) {
      const categorized = dateTimeSlots.reduce<CategorizedTimeSlots>(
        (acc, timeSlot) => {
          const formattedTime = getTimeSlotInFormat(timeSlot);
          const category = categorizeTimeSlot(formattedTime);

          if (acc[category]) {
            acc[category].push({
              ...timeSlot,
              formattedTime, // Include formattedTime in the timeSlot object
            });
          }

          return acc;
        },
        {
          morning: [],
          afternoon: [],
          evening: [],
        }
      );

      setCategorizedDateTimeSlots(categorized);
    }
  }, [dateTimeSlots]);

  useEffect(() => {
    if (selectedTime && selectedDate && !yourAvailabilitySelected) {
      // const date = getDateFormatForApi(`${selectedDate}`);
      // const appointmentDate = combineDateAndTime(date, selectedTime);
      // if (!yourAvailabilitySelected) {
      //   setAppointmentDate(appointmentDate);
      // }
    } else if (!yourAvailabilitySelected && !selectedDate && !selectedTime) {
      setAppointmentDate(null);
    }
  }, [selectedTime, selectedDate, yourAvailabilitySelected]);

  // useEffect(() => {
  //   if (
  //     selectedMeetingType &&
  //     selectedTime &&
  //     selectedDate &&
  //     yourAvailabilitySelected
  //   ) {
  //     const date = getDateFormatForApi(`${selectedDate}`);
  //     const appointmentDate = combineDateAndTime(date, selectedTime);
  //     loadAvailableDoctors(selectedMeetingType, appointmentDate);
  //   }
  // }, [
  //   selectedMeetingType,
  //   selectedTime,
  //   selectedDate,
  //   yourAvailabilitySelected,
  // ]);

  const getTextForSlotcategory = (category: string) => {
    if (category === "morning") {
      return "MORNING SLOTS";
    } else if (category === "afternoon") {
      return "AFTERNOON SLOTS";
    } else {
      return "EVENING SLOTS";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <>
        {!yourAvailabilitySelected && (
          <>
            {loading && <LoadingScreen />}

            {!loading && timeSlots.length === 0 && (
              <ContentCenter>
                <Typography
                  className="no-data"
                  sx={{ width: "max-content", paddingBlock: "1rem" }}
                >
                  No available slots
                </Typography>
              </ContentCenter>
            )}

            {!loading &&
              timeSlots?.length > 0 &&
              categorizedTimeSlots &&
              (Object.keys(categorizedTimeSlots) as TimeSlotCategory[]).map(
                (category) =>
                  categorizedTimeSlots[category].length > 0 && (
                    <Box
                      key={category}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-extra-small)",
                          lineHeight: "120%",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-bg-darkblue-primary)",
                        }}
                      >
                        {getTextForSlotcategory(category)}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "0.813rem",
                        }}
                      >
                        {categorizedTimeSlots[category].map((timeSlot: any) => (
                          <TimeSlot
                            key={timeSlot.formattedTime}
                            time={timeSlot.formattedTime}
                            isSelected={selectedTime === timeSlot.formattedTime}
                            onClick={() => handleTimeSlotClick(timeSlot)}
                          />
                        ))}
                      </Box>
                    </Box>
                  )
              )}
          </>
        )}

        {yourAvailabilitySelected && (
          <>
            {dateTimeSlotsLoading && <LoadingScreen />}

            {!dateTimeSlotsLoading && dateTimeSlots.length === 0 && (
              <ContentCenter>
                <Typography
                  className="no-data"
                  sx={{ width: "max-content", paddingBlock: "1rem" }}
                >
                  No available slots
                </Typography>
              </ContentCenter>
            )}
            {!dateTimeSlotsLoading &&
              dateTimeSlots.length > 0 &&
              categorizedDateTimeSlots &&
              (Object.keys(categorizedDateTimeSlots) as TimeSlotCategory[]).map(
                (category) =>
                  categorizedDateTimeSlots[category].length > 0 && (
                    <Box
                      key={category}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-extra-small)",
                          lineHeight: "120%",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-bg-darkblue-primary)",
                        }}
                      >
                        {categorizedDateTimeSlots[category]}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "0.75rem",
                        }}
                      >
                        {categorizedDateTimeSlots[category].map(
                          (timeSlot: any) => (
                            <TimeSlot
                              key={timeSlot.formattedTime}
                              time={timeSlot.formattedTime}
                              isSelected={
                                selectedTime === timeSlot.formattedTime
                              }
                              onClick={() => handleDateTimeSlotClick(timeSlot)}
                            />
                          )
                        )}
                      </Box>
                    </Box>
                  )
              )}
          </>
        )}
      </>
    </Box>
  );
};

export default AppointmentTimeSlots;
