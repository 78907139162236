import React, {  useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box, useMediaQuery } from "@mui/material";
import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import Checkbox from "../ui/Checkbox";
import useAppStore from "../../store/appStore";
import { LoginFormInputs } from "../../types/auth";
import { loginSchema } from "../../validations/authValidation";
import NationalHealthAuthorityLogo from "../shared/NationalHealthAuthorityLogo";
import AbhaSignUp from "./AbhaSignUp";
import VerificationModal  from "./verificationModal";
const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      whatsappUpdates: false,
    },
  });

  const { showSnackbar } = useAppStore();
  const [inputValue, setInputValue] = useState("");
  const [step, setStep] = useState<"otpTypeSelect" | "abhaAccountSelect" | "aadharOtpModal"|"mobileOtpModal"|"emailOtpModal">("otpTypeSelect");
  const [loading, setLoading] = useState(false);
  const [aadharModalOpen, setAadharModalOpen] = useState(false);
  // const [txnId, setTxnId] = useState(null);
  const [verificationOpen, setVerificationOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelowLaptop = useMediaQuery("(max-width:85.375rem)");

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setLoading(true);
    // setTxnId(null);
    try {
      const input = data.emailOrPhone.toLowerCase().trim();
      if (/^\d{14}$/.test(input)) {
        setStep("otpTypeSelect")
      } else if (/^\d{12}$/.test(input)) {
        setStep("aadharOtpModal")
      } else if (/^\d{10}$/.test(input)) {
        setStep("mobileOtpModal")
      } else {
        setStep("emailOtpModal")
      }
      setInputValue(input);
      setVerificationOpen(true);


      // const payloadData = {
      //   emailOrMobileOrAadhaarOrAbhaNumber: lowerCaseEmail || "",
      // };
      // const result = await sendOtp(payloadData);

      // if (result?.message) {
      //   setMessage(result?.message);
      // }

      // setEmail(data.emailOrPhone);
      // setOtpToken(result.otpToken);
      // if (result.txnId) {
      //   setTxnId(result.txnId);
      // }
      // setOtpOpen(true);
    } catch (error: any) {
      showSnackbar(
        error?.response?.data?.message ||
        "Could not send OTP, Please try again",
        "error"
      );
    }
    setLoading(false);
  };

  const onCloseAadharModal = () => {
    setAadharModalOpen(false);
  };

  const onCloseVerification = () => {
    setVerificationOpen(false); // New close handler for ABHA verification modal
  };


  const loginFormStyles = {
    headingStyles: {
      fontWeight: "var(--neuro-font-weight-bold)",
      fontSize: "var(--neuro-font-size-large-medium)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
      lineHeight: "120%",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      width: isMobile ? "100%" : "revert",
    },
    subHeadingStyles: {
      fontFamily: "var(--neuro-font-family-roboto-slab)",
      fontSize: "var(--neuro-font-size-x-small)",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      marginTop: "0.3125rem", // 5px,
      width: isMobile ? "100%" : "revert",
      lineHeight: "120%",
    },
    greenLineSeperator: {
      width: "4.3125rem", // 69px
      height: "0.4375rem", // 7px
      backgroundColor: "var(--neuro-button-bg-success)",
      margin: isMobile ? "1.25rem auto 1.25rem" : "2.0625rem 0rem 2.5rem", // 20px auto 20px or 33px 0 40px
    },
    whatsappSpan: {
      fontWeight: "var(--neuro-font-weight-bold)",
      fontSize: "var(--neuro-font-size-medium-smallest)",
      lineHeight: "120%",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
      color: "var(--neuro-button-bg-success) !important",
    },
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: isMobile || isBelowLaptop ? 0 : "8.5rem",
          mb: isMobile || isBelowLaptop ? 0 : "13.5rem",
          padding: 0,
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              height: "12.5rem", // 200px
              width: "100%",
              background:
                "linear-gradient(135deg, var(--neuro-button-bg-primary), var(--neuro-button-bg-success))",
              color: "var(--neuro-white-text)",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              marginBottom: "3.125rem", // 50px
              justifyContent: "flex-end",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingLeft: "1.5625rem", // 25px
                paddingBottom: "2.1875rem", // 35px
              }}
            >
              <Typography sx={loginFormStyles.headingStyles}>
                Log In to
              </Typography>
              <Typography sx={loginFormStyles.headingStyles}>
                22Neuro Clinic
              </Typography>
              <Typography sx={loginFormStyles.subHeadingStyles}>
                Log In / Create account with 22Neuro Clinic
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography sx={loginFormStyles.headingStyles}>
              Log In / Create account with
            </Typography>
            <Typography sx={loginFormStyles.headingStyles}>
              22Neuro Clinic
            </Typography>
          </>
        )}

        {!isMobile && <Box sx={loginFormStyles.greenLineSeperator}></Box>}

        <Box
          sx={{
            paddingInline: isMobile ? "1.25rem" : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem", // 12px
              marginBottom: "0.5625rem", // 9px
            }}
          >
            <Input
              type="text"
              // label="E-mail / Phone / Aadhaar / ABHA *"
              label="E-mail / Aadhaar / ABHA"
              className="input-primary"
              {...register("emailOrPhone")}
              error={!!errors.emailOrPhone}
              helperText={errors.emailOrPhone?.message}
            />
            <Typography
              sx={{
                color: "var(--neuro-darkgrey_border)",
                marginBottom: "1.4375rem", // 23px
              }}
            >
              An OTP will be sent to this email / phone
            </Typography>
            <Checkbox
              label={
                <Typography
                  sx={{
                    fontSize: "var(--neuro-font-size-medium-smallest)",
                    lineHeight: "120%",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    color: "var(--neuro-black-text)",
                  }}
                >
                  Accept{" "}
                  <a
                    href="https://www.22neuro.com/terms-condition"
                    target="blank"
                    style={{ textDecoration: "underline" }}
                  >
                    terms and conditions
                  </a>{" "}
                </Typography>
              }
              className="checkbox-primary"
              {...register("whatsappUpdates")}
              error={!!errors.whatsappUpdates}
              helperText={errors.whatsappUpdates?.message}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "0.625rem", // 10px
              justifyContent: "space-between",
              mt: "2.5rem", // 40px
              mb: "3rem", // 48px
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={loading}
              className="success-button"
              sx={{
                textTransform: "none",
                width: isMobile ? "100%" : "200px", // 200px
                // height: "3rem", // 48px
              }}
            >
              {loading ? <Spinner /> : "Log In / Create Account"}
            </Button>
            {/* <Link
              className="universal-link"
              onClick={() => setAadharModalOpen(true)}
            >
              Create Account Using Aadhaar
            </Link> */}

            <Button
              variant="outlined"
              className="outlined-primary-button"
              sx={{ p: "0rem 1rem !important" }}
              startIcon={
                <NationalHealthAuthorityLogo
                  // width="6.25rem"
                  // height="2.188rem"
                  width="max-content"
                  height="2.188rem"
                />
              }
              onClick={() => setAadharModalOpen(true)}
            >
              Create Account Using Aadhaar
            </Button>
          </Box>
        </Box>
      </Box>

      <VerificationModal 
        input={inputValue}
        step={step}
        setStep={setStep}
        open={verificationOpen} 
        onClose={onCloseVerification}
      />
      <AbhaSignUp open={aadharModalOpen} onClose={onCloseAadharModal} />
    </>
  );
};

export default LoginForm;
